import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  scrollContainer: {
    overflowY: "hidden",
    // whiteSpace: "nowrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  homeBanner: {
    background: "#FFF",
    width: "100%",
    marginRight: "48px",
  },
  cardName: {
    textAlign: "center",
    color: "#212121",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.5px",
  },
  pdlr120: {
    padding: "0px 120px",
  },

  kmGallery: {
    color: "#212121",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.1px",
    marginBottom: "24px",
  },
  cusName: {
    color: "#212121",
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: "normal",
  },
  reviewText: {
    color: "#212121",
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "120%",
    letterSpacing: "0.15px",
    width: "420px",
  },
  mt30: {
    marginTop: "30px",
  },
  reviewTag: {
    width: "6px",
    height: "8px",
    marginRight: "6px",
  },
  userType: {
    color: "#ff4e00",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "10px",
    letterSpacing: "0.6px",
  },
  /* Container holding all the images */
  imageContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "nowrap",
  },

  ratingImg: {
    width: "100px" /* Adjust to the desired image width */,
    height: "100px" /* Maintain aspect ratio */,
    marginRight: "16px" /* Space between images */,
    objectFit: "contain",
    flexShrink: 0 /* Prevent shrinking of images */,
  },

  /* Keyframes to animate the horizontal scroll */
  "@keyframes scroll-left-to-right": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },

  "@media (max-width: 550px)": {
    homeBannerCard: {
      maxWidth: "550px",
      "& a": {
        width: "100%",
      },
      "& img": {
        width: "100%",
      },
    },
    homeBanner: {
      marginTop: "0px",
      width: "550px",
      marginRight: "32px",
    },
    kmGallery: {
      fontSize: "15px",
      fontWeight: "600",
      lineHeight: "110%",
      letterSpacing: "0.1px",
      padding: "0px 0px 12px 0px",
      margin: 0,
    },
    scrollContainer: {
      padding: "0px",
    },
    reviewText: {
      width: "320px",
    },
    trainingCard: {
      margin: "16px",
      background: "#FDFDFD",
    },
    carouselBannerDiv: {
      padding: "8px 16px",
      maxWidth: "550px",
      minWidth: "328px",
      height: 150,
      "& img": {
        display: "block",
        width: "100%",
        height: "100%",
      },
    },
    ratingImg: {
      width: "57px",
      height: "74px",
    },
    reviewTag: {
      width: "6px",
      height: "8px",
      marginRight: "6px",
    },
    mr0: {
      margin: 0,
    },
  },
  carouselBannerDiv: {
    padding: "8px 16px",
    maxWidth: "550px",
    minWidth: "328px",
    height: 150,
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },

  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
}));

export default style;
