import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function MobileVpcc() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div className={commClasses.mtb48lr120}>
        <div
          className={clsx(
            commClasses.fs15fw6,
            commClasses.fw700,
            commClasses.mB24
          )}
        >
          KheloMore Cricket Coaching (12+ years)
        </div>
        <div className={clsx(classes.scrollContainer, commClasses.flex)}>
          <div>
            <a
              href="https://www.khelomore.com/sports-coaching/society/vpcc-wings-sports-centre/mumbai/357"
              target="_blank"
              className={clsx(
                classes.catCard,
                commClasses.br16,
                commClasses.mT12
              )}
            >
              <div className={clsx(commClasses.flex, commClasses.startAlign)}>
                <div>
                  <img
                    className={clsx(classes.venueImg)}
                    src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-bandra.png"
                    alt="image"
                  />
                </div>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.flexCol,
                    commClasses.mL16
                  )}
                >
                  <h4 className={clsx(commClasses.fs14fw6, commClasses.noWrap)}>
                    VPCC - Wings <br /> Bandra (West)
                  </h4>
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.black21,
                      commClasses.displayInlineBlock,
                      commClasses.mT8
                    )}
                  >
                    120+ students enrolled
                  </span>
                </div>
              </div>
            </a>
            <a
              href="https://www.khelomore.com/sports-coaching/society/-saraswati-sports-complex/mumbai/360"
              target="_blank"
              className={clsx(
                classes.catCard,
                commClasses.br16,
                commClasses.mT12
              )}
            >
              <div className={clsx(commClasses.flex, commClasses.startAlign)}>
                <div>
                  <img
                    className={clsx(classes.venueImg)}
                    src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-malad.png"
                    alt="image"
                  />
                </div>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.flexCol,
                    commClasses.mL16
                  )}
                >
                  <h4 className={clsx(commClasses.fs14fw6, commClasses.noWrap)}>
                    VPCC - Saraswati Sports <br /> Malad (West)
                  </h4>
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.black21,
                      commClasses.displayInlineBlock,
                      commClasses.mT8
                    )}
                  >
                    120+ students enrolled
                  </span>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://www.khelomore.com/sports-coaching/society/-don-bosco-cricket-academy/mumbai/358"
              target="_blank"
              className={clsx(
                classes.catCard,
                commClasses.br16,
                commClasses.mT12
              )}
            >
              <div className={clsx(commClasses.flex, commClasses.startAlign)}>
                <div>
                  <img
                    className={clsx(classes.venueImg)}
                    src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-matunga.png"
                    alt="image"
                  />
                </div>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.flexCol,
                    commClasses.mL16
                  )}
                >
                  <h4 className={clsx(commClasses.fs14fw6, commClasses.noWrap)}>
                    VPCC - Don Bosco, <br /> Matunga (East)
                  </h4>
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.black21,
                      commClasses.displayInlineBlock,
                      commClasses.mT8
                    )}
                  >
                    120+ students enrolled
                  </span>
                </div>
              </div>
            </a>
            {/* <div
              className={clsx(
                classes.catCard,
                commClasses.br16,
                commClasses.mT12,
              )}
            >
              <div className={clsx(commClasses.flex, commClasses.startAlign)}>
                <div>
                  <img
                    className={clsx(classes.venueImg)}
                    src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-cst.png"
                    alt="image"
                  />
                </div>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.flexCol,
                    commClasses.mL16
                  )}
                >
                  <h4
                    className={clsx(
                      commClasses.fs14fw6,
                      commClasses.noWrap
                    )}
                  >
                    VPCC - <br /> National, CST
                  </h4>
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.black21,
                      commClasses.displayInlineBlock,
                      commClasses.mT8
                    )}
                  >
                    120+ students enrolled
                  </span>
                </div>
              </div>
            </div> */}
            <a
              href="https://www.khelomore.com/sports-coaching/society/vpcc-sarjapur/bengaluru/366"
              target="_blank"
              className={clsx(
                classes.catCard,
                commClasses.br16,
                commClasses.mT12
              )}
            >
              <div className={clsx(commClasses.flex, commClasses.startAlign)}>
                <div>
                  <img
                    className={clsx(classes.venueImg)}
                    src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-thane.png"
                    alt="image"
                  />
                </div>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.flexCol,
                    commClasses.mL16
                  )}
                >
                  <h4 className={clsx(commClasses.fs14fw6, commClasses.noWrap)}>
                    VPCC <br /> Sarjapur, Bengaluru
                  </h4>
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.black21,
                      commClasses.displayInlineBlock,
                      commClasses.mT8
                    )}
                  >
                    120+ students enrolled
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
