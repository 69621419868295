import { makeStyles } from "@material-ui/core/styles";

const commonStyles = makeStyles((theme) => ({
    pointer: {
        cursor: "pointer",
      },
      auto: {
        overflow: "auto",
      },
      displayBlock:{
        display:"block"
      },
      displayInlineBlock:{
        display:"inline-block"
      },
      objectFitContain:{
        objectFit:"contain"
      },
      noWrap:{
        whiteSpace:"nowrap"
      },
      //flex
      flex: {
        display: "flex",
      },
      flexCol: {
        flexDirection: "column",
      },
      flexWrap: {
        flexWrap: "wrap",
      },
      spaceBw: {
        justifyContent: "space-between",
      },
      center: {
        justifyContent: "center",
      },
      startAlign: {
        alignItems: "start",
      },
      centerAlign: {
        alignItems: "center",
      },
      bottomAlign: {
        alignItems: "baseline",
      },
      end: {
        justifyContent: "end",
      },
      relative: {
        position: "relative",
      },
    
      //typrography
      fs10fw6: {
        color: "#212121",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "10px" /* 100% */,
        letterSpacing: "0.075px",
      },
      fs12fw6: {
        color: "#212121",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "12px" /* 100% */,
        letterSpacing: "0.15px",
      },
      fs13fw6: {
        color: "#212121",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "13px" /* 100% */,
        letterSpacing: "0.1px",
      },
      fs14fw6: {
        color: "#212121",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "14px" /* 100% */,
        letterSpacing: "0.4px",
      },
      fs15fw6: {
        color: "#212121",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "15px" /* 100% */,
        letterSpacing: "0.1px",
      },
      fs16fw6: {
        color: "#212121",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "16px" /* 100% */,
        letterSpacing: "0.1px",
      },
      fs17fw6: {
        color: "#212121",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "17px" /* 100% */,
        letterSpacing: "0.1px",
      },
      fs18fw7: {
        color: "#212121",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px" /* 100% */,
        letterSpacing: "0.15px",
      },
      fs24fw7: {
        color: "#212121",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px" /* 100% */,
      },
      fs34fw7: {
        color: "#212121",
        fontSize: "34px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "34px" /* 100% */,
      },
      lineHeight20: {
        lineHeight:"20px"
      },
      //text
      uppercase: {
        textTransform: "uppercase",
      },
      underline: {
        textDecoration: "underline",
      },
      strikethrough: {
        textDecoration: "line-through",
      },
      textJustify: {
        textAlign: "justify",
      },
    
      //colors
      white: {
        color: "#FFF",
      },
      orange: {
        color: "#FF4E00",
      },
      gray: {
        color: "#757575",
      },
      gray666: {
        color: "#666",
      },
      gray97: {
        color: "#979797",
      },
      green: {
        color: "#007E33",
      },
      gray42: {
        color: "#424242",
      },
      yellow: {
        color: "#FFFE54",
      },
      black21: {
        color:"#212121"
      },
      //background color
      bgWhite: {
        backgroundColor: "#FFF",
      },
      bgOrange: {
        backgroundColor: "#FF4E00",
      },
      bgGray: {
        backgroundColor: "#EAEBF0",
      },
      bgGrayD: {
        backgroundColor: "#bdbdbd",
      },
      bgGreen: {
        backgroundColor: "#007E33",
      },
    
      //line heights
      lh12: {
        lineHeight: "12px",
      },
      lh15: {
        lineHeight: "15px",
      },
      lh16: {
        lineHeight: "16px",
      },
      lh14: {
        lineHeight: "14px",
      },
      lh18: {
        lineHeight: "18px",
      },
      lh17: {
        lineHeight: "17px",
      },
      lh20: {
        lineHeight: "20px",
      },
      lh22: {
        lineHeight: "22px",
      },
      lh24: {
        lineHeight: "24px",
      },
    
      //font weights
      fw400: {
        fontWeight: 400,
      },
      fw500: {
        fontWeight: 500,
      },
      fw600: {
        fontWeight: 600,
      },
      fw700: {
        fontWeight: 700,
      },
      fw800: {
        fontWeight: 800,
      },
      fw900: {
        fontWeight: 900,
      },
    
      //margins
      mR4: {
        marginRight: 4,
      },
      mR6: {
        marginRight: 6,
      },
      mR8: {
        marginRight: 8,
      },
      mR12: {
        marginRight: 12,
      },
      mR16: {
        marginRight: 16,
      },
      mR20: {
        marginRight: 20,
      },
      mR24: {
        marginRight: 24,
      },
      mR32: {
        marginRight: 32,
      },
      mL4: {
        marginLeft: 4,
      },
      mL6: {
        marginLeft: 6,
      },
      mL8: {
        marginLeft: 8,
      },
      mL12: {
        marginLeft: 12,
      },
      mL16: {
        marginLeft: 16,
      },
      mL18: {
        marginLeft: 18,
      },
      mL20: {
        marginLeft: 20,
      },
      mL24: {
        marginLeft: 24,
      },
      mL32: {
        marginLeft: 32,
      },
      mT4: {
        marginTop: 4,
      },
      mT8: {
        marginTop: 8,
      },
      mT2: {
        marginTop: 2,
      },
      mT12: {
        marginTop: 12,
      },
      mT16: {
        marginTop: 16,
      },
      mT20: {
        marginTop: 20,
      },
      mT24: {
        marginTop: 24,
      },
      mT64: {
        marginTop: 64,
      },
      mT32: {
        marginTop: 32,
      },
      mB2: {
        marginBottom: 2,
      },
      mB4: {
        marginBottom: 4,
      },
      mB10: {
        marginBottom: 10,
      },
      mB8: {
        marginBottom: 8,
      },
      mB12: {
        marginBottom: 12,
      },
      mB16: {
        marginBottom: 16,
      },
      mB20: {
        marginBottom: 20,
      },
      mB24: {
        marginBottom: 24,
      },
      mB32: {
        marginBottom: 32,
      },
      mB60: {
        marginBottom: 60,
      },
      mB100: {
        marginBottom: 100,
      },
      mB150: {
        marginBottom: 150,
      },
      m4: {
        margin: 4,
      },
      m8: {
        margin: 8,
      },
      m12: {
        margin: 12,
      },
      m16: {
        margin: 16,
      },
      m20: {
        margin: 20,
      },
      m24: {
        margin: 24,
      },
      m32: {
        margin: 32,
      },
      mtb24rl16: {
        margin: "24px 16px",
      },
      mtb8rl16: {
        margin: "8px 16px",
      },
      mtb6rl12: {
        margin: "6px 12px 2px 12px",
      },
      mt24brl12: {
        margin: "24px 16px 12px 16px",
      },
      mtb24lr120:{
        margin:"24px 120px"
      },
      mtb48lr120:{
        margin:"48px 120px"
      },
      mTB4: {
        margin: "4px 0px",
      },
      mTB12: {
        margin: "12px auto",
      },
      marginauto:{
        margin:"0 auto",
      },
      //paddings
      pR4: {
        paddingRight: 4,
      },
      pR8: {
        paddingRight: 8,
      },
      pR12: {
        paddingRight: 12,
      },
      pR16: {
        paddingRight: 16,
      },
      pR20: {
        paddingRight: 20,
      },
      pR24: {
        paddingRight: 24,
      },
      pR32: {
        paddingRight: 32,
      },
      pL4: {
        paddingLeft: 4,
      },
      pL8: {
        paddingLeft: 8,
      },
      pL12: {
        paddingLeft: 12,
      },
      pL16: {
        paddingLeft: 16,
      },
      pL20: {
        paddingLeft: 20,
      },
      pL24: {
        paddingLeft: 24,
      },
      pL32: {
        paddingLeft: 32,
      },
      pT4: {
        paddingTop: 4,
      },
      pT8: {
        paddingTop: 8,
      },
      pT12: {
        paddingTop: 12,
      },
      pT16: {
        paddingTop: 16,
      },
      pT20: {
        paddingTop: 20,
      },
      pT24: {
        paddingTop: 24,
      },
      pT32: {
        paddingTop: 32,
      },
      mB4: {
        paddingBottom: 4,
      },
      pB8: {
        paddingBottom: 8,
      },
      pB12: {
        paddingBottom: 12,
      },
      pB16: {
        paddingBottom: 16,
      },
      pB20: {
        paddingBottom: 20,
      },
      pB24: {
        paddingBottom: 24,
      },
      pB32: {
        paddingBottom: 32,
      },
      p4: {
        padding: 4,
      },
      p6: {
        padding: 6,
      },
      p8: {
        padding: 8,
      },
      p12: {
        padding: 12,
      },
      p16: {
        padding: 16,
      },
      p20: {
        padding: 20,
      },
      p24: {
        padding: 24,
      },
      p32: {
        padding: 32,
      },
      p48: {
        padding: 48,
      },
      ptb24rl16: {
        padding: "24px 16px",
      },
      ptb6rl12: {
        padding: "6px 12px",
      },
      ptb10rl12: {
        padding: "10px 12px",
      },
      ptb12rl16: {
        padding: "12px 16px",
      },
      ptb48lr120: {
        padding:"48px 120px"
      },
      //border radius
      br16: {
        borderRadius: "16px",
      },
      br8: {
        borderRadius: "8px",
      },
      br10: {
        borderRadius: "10px",
      },
      br11: {
        borderRadius: "11px",
      },
      br24: {
        borderRadius: "24px",
      },
      br4: {
        borderRadius: "4px",
      },
      br20: {
        borderRadius: "20px",
      },
      br40: {
        borderRadius: "40px",
      },
      //border
      b1EEE: {
        border: "1px solid #EEE",
      },
      b1321: {
        border: "1.3px solid #212121",
      },
      b1F3: {
        border: "1px solid #F3F3F3",
      },
      b1D4: {
        border: "1px solid #D4D4D4",
      },
      bb1EEE: {
        borderBottom: "1px solid #EEE",
      },
      //hr
      hr: {
        height: "1px",
        background: "#EEE",
        width: "100%",
      },
      orangeAccent: {
        color: "#FF4E00",
      },
      //
      w100: {
        width: "100%",
      },
      w80: {
        width: "80%",
      },
      w55: {
        width: "55%",
      },
      w50: {
        width: "50%",
      },
      w45: {
        width: "45%",
      },
      w30: {
        width: "30%",
      },
      w20: {
        width: "20%",
      },
      textCenter: {
        textAlign: "center",
      },

    "@media (max-width:550px)": {
      mtb48lr120:{
        margin:"32px 16px"
      },
      mtb24lr120:{
        margin:"32px 16px"
      },
    }

}));

export default commonStyles;
