import React from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import useStyles from "./styles";
import DeskRedirection from "./DeskRedirection";

const RedirectionCard = () => {
  const classes = useStyles();

 
  return (
    <>
      <DeskRedirection />
    </>
  );
};

export default RedirectionCard;
