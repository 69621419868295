import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { TRAINING_BY_COACHES } from "../../gqlOperations/queries";
import useStyles from "./styles";

import MobileTrainingVenue from "./MobileTrainingVenue";
import DeskTrainingVenue from "./DeskTrainingVenue";
import TrainingCard from "./TrainingCard";

export default function TrainingVenue() {
  const classes = useStyles();
  const scrl = useRef(null);
  const { loading, error, sdata } = useQuery(TRAINING_BY_COACHES);
  // console.log(data,"->>")

  const data = [
    {
      attributes: {
        nameOfTheCamp: "Prabodhankar Thackeray Krida Sankul",
        mode: "mumbai",
        cardURL:
          "https://www.khelomore.com/sports-venues/mumbai/ptks-vile-parle-east/1840",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/mp-1.png",
        address: "Vile Parle East, Kankuwadi, Mumbai",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "CSM Sports Complex",
        mode: "mumbai",
        cardURL:
          "https://www.khelomore.com/sports-venues/miraroad/csm-sports-complex,-andheri-west-andheri-west/1947",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/mp-2.png",
        address: "Andheri West, Mumbai",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Celebrations Club - Victory x Pickle + x SSP",
        mode: "mumbai",
        cardURL:
          "https://www.khelomore.com/sports-venues/mumbai/victory-sports-x-super-sports-park-celebrations-club-andheri-west/1858",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/mp-3.png",
        address: "Andheri West, Mumbai",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "SNDT Matunga Victory Sports x SSP",
        mode: "mumbai",
        cardURL:
          "https://www.khelomore.com/sports-venues/mumbai/sndt-matunga-victory-sports-x-ssp-matunga-east/1976",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/mp-4.png",
        address: "Matunga East, Mumbai",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        __typename: "GetTrainedAtHome",
        nameOfTheCamp: "SIWS Wadala Victory Sports x SSP",
        mode: "mumbai",
        cardURL:
          "https://www.khelomore.com/sports-venues/mumbai/siws-wadala-victory-sports-x-ssp-wadala/1974",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/mp-5.png",
        address: "Wadala, Mumbai",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Rush Arena",
        mode: "bengaluru",
        cardURL:
          "https://www.khelomore.com/sports-venues/bengaluru/rush-arena-cooke-town-maruthi-sevanagar/2002",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/bp-1.png",
        address: "Cooke Town, Bengaluru",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Pickle Point",
        mode: "bengaluru",
        cardURL:
          "https://www.khelomore.com/sports-venues/bengaluru/pickle-point-rt-nagar/2047",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/bp-2.png",
        address: "R.T. Nagar, Bengaluru",
        offer: "FLAT Rs.150 OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "KSPA Pickleball Court",
        mode: "bengaluru",
        cardURL:
          "https://www.khelomore.com/sports-venues/bengaluru/karnataka-state-pickleball-association-(kspa)-pay-n-play-nagarbhavi/2011",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/bp-3.png",
        address: "Marilingappa Layout, Bengaluru",
        offer: "FLAT Rs.150 OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Unorthodox Sports Arena",
        mode: "bengaluru",
        cardURL:
          "https://www.khelomore.com/sports-venues/bengaluru/unorthodox-sports-arena-off-sarjapur-road/1977",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/bp-4.png",
        address: "Halanayakanahalli, Bengaluru",
        offer: "FLAT Rs.150 OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Padukone - Dravid Centre for Sports Excellence",
        mode: "bengaluru",
        cardURL:
          "https://www.khelomore.com/sports-venues/bengaluru/padukone---dravid-centre-for-sports-excellence-yelahanka-taluk/1942",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/bp-5.png",
        address: "Yelahanka, Tharahunise Jala Hobli, Bengaluru",
        offer: "FLAT Rs.200 OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Hindu Gymkhana - Kothrud",
        mode: "pune",
        cardURL:
          "https://www.khelomore.com/sports-venues/pune/hindu-gymkhana-kothrud-kothrud/1834",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-1.png",
        address: "Kothrud, Pune",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Freekick",
        mode: "pune",
        cardURL:
          "https://www.khelomore.com/sports-venues/pune/freekick-balewadi-balewadi/898",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-2.png",
        address: "Balewadi, Pune",
        offer: "30% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Pickle+ Baner - Pickleball Courts",
        mode: "pune",
        cardURL:
          "https://www.khelomore.com/sports-venues/pune/pickle+-baner-baner/2001",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-3.png",
        address: "Baner, Pune",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "The Pickle Point",
        mode: "pune",
        cardURL:
          "https://www.khelomore.com/sports-venues/pune/the-pickle-point-hinjewadi/2037",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-4.png",
        address: "Hinjewadi, Pune",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Pickleball One - Viman Nagar",
        mode: "pune",
        cardURL:
          "https://www.khelomore.com/sports-venues/pune/pickleball-one-viman-nagar-viman-nagar/2014",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-5.png",
        address: "Viman Nagar, Pune",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Players Den",
        mode: "delhi",
        cardURL:
          "https://www.khelomore.com/sports-venues/new-delhi/players-den-tirang-pickleball-&-box-cricket-arena-rajapur/1963",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/dp-1.png",
        address: "Rohini Sector 13, New Delhi",
        offer: "30% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "AV Pickleball Academy",
        mode: "delhi",
        cardURL:
          "https://www.khelomore.com/sports-venues/new-delhi/av-pickleball-academy-pitampura/2034",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/dp-2.png",
        address: "Pitampura, New Delhi",
        offer: "20% OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Bharat Pickleball Academy",
        mode: "delhi",
        cardURL:
          "https://www.khelomore.com/sports-venues/delhi/bharat-pickleball-academy-bijwasan/1910",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/dp-3.png",
        address: "Carterpuri Rd, Delhi",
        offer: "Rs.150 OFF",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "Rackonnect 63 Badminton Arena",
        mode: "delhi",
        cardURL:
          "https://www.khelomore.com/sports-venues/gurugram/rackonnect-63-badminton-arena-sector63a/1945",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/dp-4.png",
        address: "Kadarpur, Gurugram",
        offer: "30% Off",
      },
    },
    {
      attributes: {
        nameOfTheCamp: "PlayAll Box Cricket Arena NGF | Alaknanda",
        mode: "delhi",
        cardURL:
          "https://www.khelomore.com/sports-venues/new-delhi/playall-box-cricket-arena-ngf-%7C-alaknanda-alaknanda/1922",
        imageURL:
          "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/dp-5.png",
        address: "Alaknanda, Kalka Public School Rd, New Delhi",
        offer: "30% Off",
      },
    },
  ];

  const [currentData, setCurrentData] = useState(data);
  const [activeMode, setActiveMode] = useState();
  const modes = ["Bengaluru","Mumbai","Pune","Delhi"];

  useEffect(() => {
    handleTrainingData("Bengaluru");
  }, []);

  const handleTrainingData = (mode) => {
    const filteredData = data?.filter(
      (obj) =>
        obj?.attributes?.mode?.toLocaleLowerCase() === mode?.toLocaleLowerCase()
    );
    if (mode?.toLocaleLowerCase() === activeMode) {
      setActiveMode("");
      setCurrentData(data);
    } else {
      setActiveMode(mode);
      setCurrentData(filteredData);
    }
  };

  const trainingData = currentData?.map((details) => details.attributes);

  return (
    <>
      <div className={classes.trainingCard}>
        {isMobile ? (
          <MobileTrainingVenue
            handleTrainingData={handleTrainingData}
            activeMode={activeMode}
            modes={modes}
          />
        ) : (
          <DeskTrainingVenue
            handleTrainingData={handleTrainingData}
            activeMode={activeMode}
            modes={modes}
          />
        )}
        <TrainingCard data={trainingData} loading={loading} />
      </div>
    </>
  );
}
