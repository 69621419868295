import { React, useState } from "react";
import useStyles from "../styles";
import commonStyles from "../../commonStyles";
import clsx from "clsx";

export default function SocialMedia() {
  const classes = useStyles();

  const commClasses = commonStyles();


  const socialMedia = [
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/fb-icon.svg",
      url: "https://www.facebook.com/KheloMore/",
      alt: "facebook",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/insta-icon.svg",
      url: "https://www.instagram.com/khelomoreindia/",
      alt: "instagram",
    },
    {
        src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/linkedin-icon.svg",
        url: "https://www.linkedin.com/company/khelomore/",
        alt: "linkedin",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/x-2025.svg",
      url: "https://x.com/KheloMore",
      alt: "x (twitter)",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/youtube-icon.svg",
      url: "https://www.youtube.com/channel/UC9SRqiqrQ1v9RoNX-Lnmqbg",
      alt: "youtube",
    },
  ];

  return (
    <>
      <div className={clsx(classes.flex, classes.alignCenter,classes.socialArea)}>
        {socialMedia?.map((item, idx) => (
          <a target="_blank" href={item?.url} key={item?.alt}>
            <img
              className={classes.socialIcon}
              src={item?.src}
              alt={item?.alt}
            />
          </a>
        ))}
      </div>
    </>
  );
}
