import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  scrollContainer: {
    overflowY: "hidden",
    whiteSpace: "nowrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  homeBanner: {
    "& img": {
      cursor: "pointer",
      width: "340px",
      height: 358,
      paddingRight: 16,
    },
    "&:last-child": {
      "& img": {
        paddingRight: 0,
      },
    },
  },
  cardName:{
    textAlign:"center",
    color: "#212121",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.5px",
  },
  fixHeight:{
    height:462,
  },
  kmGl1:{
    width:312,
    height:79
  },
  kmGl2:{
    height:358,
  },
  kmGl3:{
    width:324,
    height:234,

  },
  kmGl4:{
    width:324,
    height:203,
  },
  kmGl5:{
    width:330,
    height:462
  },
  kmGl6:{
    width:324,
    height:234,
  },
  kmGl7:{
    width:105,
    height:203
  },
  kmGl8:{
    width:203,
    height:203,
    marginLeft:16,
  },
  kmGl9:{
    width:203,
    height:105,
    marginRight :16,
  },
  kmGl10:{
    width:360,
    height:105,
  },
  kmGl11:{
    width:579,
    height:344,
  },
  kmGl12:{
    width:324,
    height:462,
  },
  "@media (max-width: 550px)": {
    homeBannerCard: {
      maxWidth: "550px",
      "& a": {
        width: "100%",
      },
      "& img": {
        width: "100%",
      },
    },
    scrollContainer:{
      padding:"16px"
    },
    kmGallery:{
      paddingLeft:"16px",
      fontSize:"15px",
      fontWeight:"600",
      letterSpacing:"0.1px",
      marginTop:"16px",
    },
    homeBanner: {
      "& img": {
        cursor: "pointer",
        width: "210px",
        height: 225,
        paddingRight: 16,
      },
      "&:last-child": {
        "& img": {
          paddingRight: 0,
        },
      },
    },
    blogContainer: {
      border: "1px solid #EEE",
      margin: "16px 12px",
      background: "#FDFDFD",
      borderRadius: "16px",
    },
    fixHeight:{
      height:242,
    },
    kmGl1:{
      width:156,
      height:40
    },
    kmGl2:{
      height:180,
      marginTop:8,
    },
    kmGl3:{
      width:162,
      height:117,
    },
    kmGl4:{
      width:162,
      height:101,
      marginTop:8
    },
    kmGl5:{
      width:164,
      height:232
    },
    kmGl6:{
      height:117,
      width:160,
    },
    kmGl7:{
      width:52,
      height:106,
      marginTop:0
    },
    kmGl8:{
      width:102,
      height:106,
      marginLeft:8,
      marginTop:0
    },
    kmGl9:{
      width:146,
      height:78,
      marginRight :8,
    },
    kmGl10:{
      width:140,
      height:78,
      objectFit:"initial !important"
    },
    kmGl11:{
      width:294,
      height:142,
      marginTop:8,
      objectFit:"initial"
    },
    kmGl12:{
      width:172,
      height:230,
    },
    mT0:{
      marginTop:0
    }
  },
  carouselBannerDiv: {
    padding:"8px 16px",
    maxWidth: "550px",
    minWidth: "328px",
    height: 150,
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },

  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
}));

export default style;
