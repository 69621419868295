import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function Events() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div className={clsx(commClasses.flex,classes.scrollContainer)}>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/events-1.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <p className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      commClasses.displayInlineBlock,
                      classes.cityName,
                      commClasses.mB4
                    )}
                  >
                   Bengaluru
                  </strong>
                  <br />
                  <span>
                  Discover a whole range of Pickleball and Badminton events
                  </span>
                </p>
                <div className={classes.bottomBorder}></div>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT24,
                    commClasses.mB24,
                    classes.cityName,
                    classes.mT12,
                    classes.mB12
                  )}
                >
                  Perks and Amenities
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/explore-events" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/events-2.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName,
                      commClasses.mB4
                    )}
                  >
                   Mumbai
                  </strong>
                  <br />
                  <span>
                    Explore events exclusive to KheloMore!
                  </span>
                </h4>
                <div className={classes.bottomBorder}></div>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT24,
                    commClasses.mB24,
                    classes.cityName,
                    classes.mT12,
                    classes.mB12
                  )}
                >
                  Perks and Amenities
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/explore-events" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/delhi-event.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName,
                      commClasses.mB4
                    )}
                  >
                    Delhi
                  </strong>
                  <br />
                  <span>
                  Connect with players to join the action!
                  </span>
                </h4>
                <div className={classes.bottomBorder}></div>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT24,
                    commClasses.mB24,
                    classes.cityName,
                    classes.mT12,
                    classes.mB12
                  )}
                >
                  Perks and Amenities
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/explore-events" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}




