import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  topText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.4px",
    marginRight: 32,
    whiteSpace: "nowrap",
    color: "#757575",
    marginTop: "16px",
    paddingBottom: 16,
  },
  activeCta: {
    color: "#ff4e00",
    borderBottom: "4px solid #ff4e00",
    paddingBottom: 16,
  },
  catCard: {
    marginRight:48,
    whiteSpace:"nowrap",
    width:280
  },
  venueImg: {
    width: 80,
    height: 80,
  },
  bookNowCta: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    gap: "8px",
    width: "max-content",
    height: 28,
    background: "rgba(25, 163, 255, 0.08)",
    border: "1px solid #19A3FF",
    borderRadius: "16px",
    color:"#19a3ff",
    fontSize:15,
    fontWeight:700,
    lineHeight:"12px",
    letterSpacing:"0.4px",
    whiteSpace:"nowrap",
    marginLeft:16,
  },
  bottomBorder:{
    borderBottom: "2px solid #eee",
    width:"112px",
    paddingBottom:12
  },
  scrollContainer: {
    overflowY: "hidden",
    // whiteSpace: "nowrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },  
  "@media (max-width:550px)": {
    catCard:{
      width:260,
      marginRight:32,
      display:"block",
    }
  },
}));

export default style;
