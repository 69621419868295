import { React, useState, useRef } from "react";
import clsx from "clsx";
import SectionWithScrollButtons from "../Common/SectionWithScrollButtons";
import useStyles from "./styles";
import BannerLoader from "../skeletonLoader/BannerLoader";
import commonStyles from "../commonStyles";

export default function DeskKmGallery({ cardData, loading }) {
  const classes = useStyles();
  const commClasses = commonStyles();
  const scrl = useRef(null);

  return (
    <>
    <div className={commClasses.mtb48lr120}> 
      {/* <SectionWithScrollButtons
        isBg={true}
        onClickRight={() => {
          scrl.current.scrollLeft += 250;
        }}
        onClickLeft={() => {
          scrl.current.scrollLeft -= 250;
        }}
      > */}
        <div
          className={clsx(classes.flex, classes.scrollContainer)}
          id="content"
          ref={scrl}
        >
      {loading ? <BannerLoader /> :
          <div className={clsx(commClasses.flex,classes.fixHeight)}>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div>
                <img className={clsx(classes.kmGl1,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-1.png" alt="km gallery" />
              </div>
              <div>
                <img className={clsx(classes.kmGl1,classes.kmGl2,commClasses.mT16,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-2.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div>
                <img className={clsx(classes.kmGl3,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-3.png" alt="km gallery" />
              </div>
              <div>
                <img className={clsx(classes.kmGl4,commClasses.mT16,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-4.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol)}>
              <div>
                <img className={clsx(classes.kmGl5,commClasses.displayInlineBlock,commClasses.objectFitContain,commClasses.mR16)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-5.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div>
                <img className={clsx(classes.kmGl6,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-6.png" alt="km gallery" />
              </div>
              <div className={clsx(commClasses.flex,commClasses.mT16,classes.mT0)}>
                <img className={clsx(classes.kmGl7,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-7.png" alt="km gallery" />
                <img className={clsx(classes.kmGl8,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-8.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol)}>
              <div>
                <img className={clsx(classes.kmGl5,commClasses.displayInlineBlock,commClasses.objectFitContain,commClasses.mR16)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-9.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div className={clsx(commClasses.flex)}>
                <img className={clsx(classes.kmGl9,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-10.png" alt="km gallery" />
                <img className={clsx(classes.kmGl10,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-11.png" alt="km gallery" />
              </div>
              <div>
                <img className={clsx(classes.kmGl11,commClasses.displayInlineBlock,commClasses.objectFitContain,commClasses.mT16)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-12.png" alt="km gallery" />
              </div>
            </div>
            <div>
              <img className={clsx(classes.kmGl12,commClasses.displayInlineBlock,commClasses.mR16)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-13.png" alt="km gallery" />
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div>
                <img className={clsx(classes.kmGl3,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-14.png" alt="km gallery" />
              </div>
              <div>
                <img className={clsx(classes.kmGl4,commClasses.mT16,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-15.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div>
                <img className={clsx(classes.kmGl1,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-16.png" alt="km gallery" />
              </div>
              <div>
                <img className={clsx(classes.kmGl1,classes.kmGl2,commClasses.mT16,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-17.png" alt="km gallery" />
              </div>
            </div>
            <div className={clsx(commClasses.flex,commClasses.flexCol,commClasses.mR16)}>
              <div>
                <img className={clsx(classes.kmGl3,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-18.png" alt="km gallery" />
              </div>
              <div>
                <img className={clsx(classes.kmGl4,commClasses.mT16,commClasses.displayInlineBlock,commClasses.objectFitContain)} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-19.png" alt="km gallery" />
              </div>
            </div>
          </div>
        } 
        </div>
      {/* </SectionWithScrollButtons> */}
    </div>
    </>
  );
}
