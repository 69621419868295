import React from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import { HERO_CAROUSELS } from "../../gqlOperations/queries";
import useStyles from "./styles";
import DesktopCarousel from "./DesktopCarousel";
import DeskCarouselCard from "./DeskCarouselCard";
import MobileCarousel from "./MobileCarousel";
import SocialMedia from "./SocialMediaSection/SocialMedia";

const Carousels = (props) => {
  const classes = useStyles();

  const { error, loading, data } = useQuery(HERO_CAROUSELS);

  const carouselsData = data?.heroCarousels.data.map(
    (imgs) => imgs?.attributes?.imageURL
  );

  return (
    <>
      {/* {isMobile ? <MobileCarousel /> : <DesktopCarousel data={carouselsData} loading={loading} />} */}
      {isMobile ? "": <SocialMedia />}
      {<DeskCarouselCard />}
    </>
  );
};

export default Carousels;
