import { React, useState, useRef } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import BannerLoader from "../skeletonLoader/BannerLoader";
import commonStyles from "../commonStyles";
import SectionWithScrollButtons from "../Common/SectionWithScrollButtons";

export default function DeskVenueCards({ cardData, loading }) {
  const classes = useStyles();

  const commClasses = commonStyles();
  const scrl = useRef(null);


  return (
    <>
      <div className={classes.venueContainer}>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.alignCenter,
            classes.popularVenueHead
          )}
        >
          <div className={clsx(classes.flex, classes.alignCenter)}>
            <h2 className={clsx(classes.popularVenueText)}>Find A Venue</h2>
          </div>
          <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              target="_blank"
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://www.khelomore.com/sports-venues"
            >
              Explore Sports Venue
            </a>
          </div>
        </div>

        <div>
          {loading ? (
            <BannerLoader />
          ) : (
            // <SectionWithScrollButtons
            //   isBg={true}
            //   onClickRight={() => {
            //     scrl.current.scrollLeft += 250;
            //   }}
            //   onClickLeft={() => {
            //     scrl.current.scrollLeft -= 250;
            //   }}
            // >
              <div
                className={clsx(
                  classes.flex,
                  classes.scrollContainer,
                  classes.relative,
                  commClasses.spaceBw
                )}
              >
                {cardData?.map((details, idx) => (
                  <div key={idx} className={classes.relative}>
                    {details?.tag === "Popular" ? (
                      <div className={clsx(classes.popularTag)}>
                        <h6>{details?.tag}</h6>
                      </div>
                    ) : details?.tag === "New" ? (
                      <div className={clsx(classes.newTag)}>
                        <h6>{details?.tag}</h6>
                      </div>
                    ) : null}
                    <a
                      target="_blank"
                      key={idx}
                      href={details.redirectionURL}
                      className={classes.homeBanner}
                    >
                      <img src={details?.imageURL} alt="sport card" />
                      <div className={clsx(classes.sportTt, classes.sportName)}>
                        {details?.sportName}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            // </SectionWithScrollButtons>
          )}
        </div>
      </div>
    </>
  );
}
