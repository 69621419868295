import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function VpccDesk() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div className={commClasses.mtb48lr120}>
        <div className={commClasses.fs24fw7}>
          KheloMore Cricket Coaching (12+ years)
        </div>
        <div
          className={clsx(
            commClasses.flex,
            commClasses.spaceBw,
            // commClasses.flexWrap,
            classes.scrollContainer
          )}
        >
          <a
            href="https://www.khelomore.com/sports-coaching/society/vpcc-wings-sports-centre/mumbai/357"
            target="_blank"
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.br16,
              commClasses.mT24,
              commClasses.w30
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-bandra.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20)}>
                VPCC - Wings <br/> Bandra (West)
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16,
                  )}
                >
                  120+ students enrolled
                </span>
              </div>
            </div>
          </a>
          <a
            href="https://www.khelomore.com/sports-coaching/society/-don-bosco-cricket-academy/mumbai/358"
            target="_blank"
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.br16,
              commClasses.mT24,
              commClasses.w30
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-matunga.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20)}>
                VPCC - Don Bosco, <br /> Matunga (East)
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16,
                  )}
                >
                  120+ students enrolled
                </span>
              </div>
            </div>
          </a>
          <a
            href="https://www.khelomore.com/sports-coaching/society/vpcc-sarjapur/bengaluru/366"
            target="_blank"
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.br16,
              commClasses.mT24,
              commClasses.w30
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-thane.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20)}>
                VPCC <br /> Sarjapur, Bengaluru
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16,
                  )}
                >
                 120+ students enrolled
                </span>
              </div>
            </div>
          </a>
          <a
            href="https://www.khelomore.com/sports-coaching/society/-saraswati-sports-complex/mumbai/360"
            target="_blank"
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.br16,
              commClasses.mT24,
              commClasses.w30
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-malad.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20)}>
                VPCC - Saraswati Sports <br />  Malad (West)
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16
                  )}
                >
                  120+ students enrolled
                </span>
              </div>
            </div>
          </a>
          {/* <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.br16,
              commClasses.mT24,
              commClasses.w30
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-cst.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20)}>
                VPCC - <br /> National, CST 
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16,
                  )}
                >
                  120+ students enrolled
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
