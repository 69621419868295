import React from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import useStyles from "./styles";
import DeskCategory from "./DeskCategory";

const Category = () => {
  const classes = useStyles();

 
  return (
    <>
      <DeskCategory />
    </>
  );
};

export default Category;
