import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function SportsVenue() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div className={clsx(commClasses.flex,commClasses.spaceBw,classes.scrollContainer)}>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/banglore-venue.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                  Book sports venues in <br />
                  <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      classes.bottomBorder,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName
                    )}
                  >
                    Bengaluru
                  </strong>
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16,
                    commClasses.mB16,
                    classes.cityName,
                  )}
                >
                  Avail 30% off* on select venues
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a target="_blank" href="https://www.khelomore.com/sports-venues/bengaluru">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/mumbai-venue.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray, classes.bookText)}>
                  Book sports venues in <br />
                  <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      classes.bottomBorder,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName
                    )}
                  >
                    Mumbai
                  </strong>
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    commClasses.mT16,
                    commClasses.mB16,
                    classes.cityName,
                  )}
                >
                  Avail 30% off* on select venues
                </span>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    classes.cityName,
                    commClasses.mB16
                  )}
                >
                  Get up to Rs.120 off* on
                  pickeball venues
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/mumbai" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pune-venue.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                  Book sports venues in <br />
                  <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      classes.bottomBorder,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName
                    )}
                  >
                    Pune
                  </strong>
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    classes.cityName,
                    commClasses.mT16,
                    commClasses.mB16
                  )}
                >
                  Avail 30% off* on select venues
                </span>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    classes.cityName,
                    commClasses.mB16
                  )}
                >
                  Get up to Rs.120 off* on
                  tennis venues
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/pune" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>  
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/hyd-venue.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                  Book sports venues in <br />
                  <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      classes.bottomBorder,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName
                    )}
                  >
                    Hyderabad
                  </strong>
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    classes.cityName,
                    commClasses.mT16,
                    commClasses.mB16
                  )}
                >
                  Easy Cancellation and Refunds on Box Cricket and Football venues!
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/hyderabad" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.catCard,
              commClasses.p12,
              commClasses.b1EEE,
              commClasses.br16,
              commClasses.mT24,
              commClasses.mR24
            )}
          >
            <div className={clsx(commClasses.flex, commClasses.startAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/varoda-venue.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.gray,classes.bookText)}>
                  Book sports venues in <br />
                  <strong
                    className={clsx(
                      commClasses.fs24fw7,
                      commClasses.fw600,
                      commClasses.black21,
                      classes.bottomBorder,
                      commClasses.mT4,
                      commClasses.displayInlineBlock,
                      classes.cityName
                    )}
                  >
                    Vadodara
                  </strong>
                </h4>
                <span
                  className={clsx(
                    commClasses.fs15fw6,
                    commClasses.black21,
                    commClasses.displayInlineBlock,
                    classes.cityName,
                    commClasses.mT16,
                    commClasses.mB16
                  )}
                >
                  Rs. 200 off* on selected Pickleball
                  venues
                </span>
                <div
                  className={clsx(
                    commClasses.flex,
                    commClasses.bottomAlign,
                    commClasses.spaceBw
                  )}
                >
                  <span
                    className={clsx(
                      commClasses.fs12fw6,
                      commClasses.gray,
                      commClasses.displayInlineBlock,
                      commClasses.noWrap,
                      classes.tc
                    )}
                  >
                    *T&C Apply
                  </span>
                  <a href="https://www.khelomore.com/sports-venues/vadodara" target="_blank">
                    <button className={classes.bookNowCta}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}




