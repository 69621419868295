import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import DeskPartners from "./DeskPartners";



 function PowerPartner() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.trainingCard}>
          <DeskPartners />
      </div>
    </>
  );
};

export default PowerPartner;

