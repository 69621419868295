import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({

    footerContainer:{
        background: "#212121",
        marginTop: "8px",
        padding: "48px 120px",
    },
    textBoxHead:{
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "26px",
        letterSpacing: "1.3px",
        color: "#FFFFFF",
    },
    textBoxPara:{
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0.933333px",
        color: "#ACACAC",
        margin: "8px 0px 32px 0px",
    },
    hrFooter:{
        border: "none",
        margin: 0,
        borderStyle: "solid",
        borderWidth: "1px", 
        height:"3px",
        backgroundColor:"#979797",
    },
    consumers:{
        padding:"24px 56px"
    },
    borderRight:{
        borderRight:"1px solid #979797",
        paddingRight:"20px"
    },
    consumersHead:{
        marginBottom: "11px",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.16px",
        color: "#FFFFFF"
    },
    consumersText:{
        display: "block",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "23px",
        letterSpacing: "0.105882px",
        color: "#ACACAC",
        whiteSpace:"nowrap",
        marginRight:"20px"
    },
    consumersTextEnd:{
        display: "block",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "23px",
        letterSpacing: "0.105882px",
        color: "#FFFFFF",
        whiteSpace:"nowrap",
        marginRight:"20px"
    },
    socialMediaContainer:{
        paddingLeft:"20px"
    },
    socialMediaHead:{
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.16px",
        color: "#FFFFFF",
        marginBottom:"11px"

    },
    socialMediaIcon:{
        height: "32px",
        marginRight:"16px"
    },
    downloadBox:{
        marginTop:"35px"
    },
    downloadText:{
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.16px",
        color: "#FFFFFF",
        marginBottom:"8px"
    },
    appleStoreFooter:{
        background:"#FFFFFF",
        marginRight: "10px",
    },
    contactSection:{
        paddingLeft:"20px"
    },
    contactHead:{
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.16px",
        color: "#FFFFFF"
    },
    contactPadding:{
        padding: "11px 0px"   
    },
    contactPara:{
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.1px",
        color: "#ACACAC",
    },
    contactNumber:{
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.1px",
        color: "#ACACAC",
        display:"block"
    },
    copyright:{
        height: "48px",
        background: "#212121"
    },
    copyrightText:{
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.4px",
        color: "#FFFFFF",
        textTransform:"uppercase"
    },
    footerCardsContainer:{
        padding: "25px 56px"
    },
    footerCard:{
        width: "280px",
        height: "54px",
    },
    footerCardMZero:{
        margin: 0,
    },
    footerCardIcon:{
        width: "54px",
        height: "54px",
        backgroundColor: "#FFFFFF",
        border: "0.64881px solid #979797",
        borderRadius: "5.19048px",
        padding: "5px"
    },
    footerCardHead:{
        fontWeight: 700,
        // whiteSpace:"noWrap",
        marginBottom:"6px",
        fontSize: "15px",
        lineHeight: "15px",
        letterSpacing: "0.16px",
        color: "#FFFFFF"
    },
    footerCardText:{
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.16px",
        color: "#ACACAC"
    },
    footerCardTextBox:{
        margin: "0px 16px"
    },
    socialIcon:{
        width:"32px",
        height:"32px",
    },
    "@media (max-width:550px)":{

        footerContainer:{
            background: "#212121",
            marginTop: "8px",
            padding: "16px 16px 0px 16px",
        },
        textBoxHead:{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "14px",
            letterSpacing: "1.2px",
            color: "#FFFFFF",
        },
        textBoxPara:{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.933333px",
            color: "#C7C7C7",
            margin: "16px 0px 32px 0px",
        },
        hrFooter:{
            border: "none",
            margin: 0,
            borderStyle: "solid",
            borderWidth: "1px", 
            height:"3px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
        consumers:{
            padding:"0"
        },
        consumersHead:{
            margin: "26px 0px 18px 0px",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.16px",
            color: "#FFFFFF"
        },
        consumersCard:{
            marginRight: "0px"
        },
        consumersText:{
            display: "block",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "23px",
            letterSpacing: "0.105882px",
            color: "#C7C7C7",

        },
        consumersTextEnd:{
            display: "block",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "23px",
            letterSpacing: "0.105882px",
            color: "#FFFFFF",
        },
        endText:{
            marginBottom:"22px"
        },
        consumersCardEnd:{
            margin: "32px 0px 24px 0px",
        },
        socialMediaContainer:{
            padding: "24px 0px",
        },
        socialMediaHead:{
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.16px",
            color: "#FFFFFF",
            marginBottom:"0"
        },
        socialMediaIcon:{
            height: "32px",
            margin: "8px 24px 32px 0px",
        },
        downloadText:{
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.16px",
            color: "#FFFFFF",
            marginBottom: "8px",
        },
        appleStoreFooter:{
            background:"#FFFFFF",
            marginRight: "10px",
        },
        contactSection:{
            paddingTop: 24,
            paddingLeft:0,
        },
        contactHead:{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "14px",
            letterSpacing: "0.933333px",
            color: "#FFFFFF"
        },
        contactText:{
            padding: "14px 0px"   
        },
        contactPara:{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.8px",
            color: "#C7C7C7",


        },
        contactNumber:{
            display: "block"
        },
        copyright:{
            height: "34px",
            background: "#000000",
            padding:"24px 0px 80px 0px"
        },
        copyrightText:{
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "10px",
            letterSpacing: "0.666667px",
            color: "#FFFFFF",
            textTransform:"capitalize"
        },
        footerCardsContainer:{
            padding: "25px 0px"
        },
        footerCard:{
            width: "164px",
            height: "35px",
            marginBottom: "11px",
        },
        footerCardMZero:{
            margin: 0,
        },
        footerCardIcon:{
            width: "35px",
            height: "35px",
            backgroundColor: "#FFFFFF",
            border: "0.64881px solid #979797",
            borderRadius: "5.19048px",
            padding: "5px"
        },
        footerCardHead:{
            fontWeight: 700,
            whiteSpace:"noWrap",
            marginBottom:"6px",
            fontSize: "10px",
            lineHeight: "10px",
            letterSpacing: "0.10381px",
            color: "#FFFFFF"
        },
        footerCardText:{
            fontWeight: 400,
            fontSize: "8px",
            lineHeight: "10px",
            letterSpacing: "0.10381px",
            color: "#C7C7C7",
        },
        footerCardTextBox:{
            margin: "10px 11px 12px"
        },
      },
      flex:{
        display:"flex"
    }, 
    flexWrap:{
        flexWrap:"wrap"
    },
    spaceBw:{
        justifyContent:"space-between"
    },
    spcaeEvenly:{
        justifyContent:"space-evenly"
    },
    justifyEnd:{
        justifyContent:"end"
    },
    alignCenter:{
        alignItems: "center"
    },
    flexCol:{
        flexDirection:"column"
    },
    justifyCenter:{
        justifyContent: "center"
    },
    relative:{
        position: "relative"
    },
    absolute:{
        position: "absolute"
    },
    alignEnd:{
        alignItems: "end"
    },
    flex30:{
        flex:"0 1 30%"
    }
}));

export default style;


