import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import right_Arrow from "../../icons/desRightArrow.svg";
import BlogLoader from "../skeletonLoader/BlogLoader";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function MobileBlog({ data, loading }) {
  const classes = useStyles();

  const blogData = [
    {
      blogNo: "01",
      publishDate: "23rd July 2024 | 2 mins read",
      blogTitle: "Rising Stars: Kishore Jena and Neeraj Chopra...",
      blogLink:
        "https://blog.khelomore.com/kishore-jena-and-neeraj-chopra/",
      imageUrl:
        "https://cdn-ilckmjl.nitrocdn.com/MQFWtjdgKqDgzrenAjmMgXQMbtBBmJeV/assets/images/optimized/blog.khelomore.com/wp-content/uploads/2024/07/2T05DEP-scaled-1-2048x1366.jpg",
    },
    {
      blogNo: "02",
      publishDate: "11th July 2024 | 2 mins read",
      blogTitle: "Empowering Innings: The Rise of Women’s...",
      blogLink:
        "https://blog.khelomore.com/empowering-innings-the-rise-of-womens-cricket-worldwide/",
      imageUrl:
        "https://blog.khelomore.com/wp-content/uploads/2024/07/new-wem-2048x929.png",
    },
    {
      blogNo: "03",
      publishDate: "28th May 2024 | 1 min read",
      blogTitle:
        "And that was the Final",
      blogLink: "https://blog.khelomore.com/and-that-was-the-final/",
      imageUrl:
        "https://blog.khelomore.com/wp-content/uploads/2024/05/ipl-final.png",
    },
    {
      blogNo: "04",
      publishDate: "17th May 2024 | 2 mins read",
      blogTitle: "Cricketing Glory: India’s World Cup Jour...",
      blogLink:
        "https://blog.khelomore.com/cricketing-glory-indias-world-cup-journey-1975-2023/",
      imageUrl:
        "https://blog.khelomore.com/wp-content/uploads/2024/05/wc-journey-2048x936.png",
    },
  ];

  return (
    <>
      <div className={clsx(classes.blogContainer)}>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.popularVenueHead,
            classes.alignCenter
          )}
        >
          <h2 className={clsx(classes.popularVenueText)}>Blogs and Articles</h2>
          <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              target="_blank"
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://blog.khelomore.com/"
            >
              View all{" "}
            </a>
          </div>
        </div>
        <div className={clsx(classes.flex, classes.blogCard)}>
          {loading ? (
            <BlogLoader />
          ) : (
            <Carousel
              autoPlay
              infiniteLoop
              showIndicators={blogData?.length > 1}
              showStatus={false}
              showThumbs={false}
              interval={4000}
              showArrows={false}
            >
              {blogData?.map((data, idx) => (
                <div key={idx} className={classes.flex}>
                  <a
                    className={clsx(classes.blogImageCard)}
                    target="_blank"
                    href={data?.blogLink}
                  >
                    <div className={classes.linearBox}></div>
                    <img
                      className={clsx(classes.blogImage)}
                      src={data?.imageUrl}
                      alt="image"
                    />
                    <div className={classes.flex}>
                      <div className={classes.blogNum}>{data?.blogNo}</div>
                      <div>
                        <div className={classes.publishDate}>
                          {data?.publishDate}
                        </div>
                        <h2 className={clsx(classes.blogText)}>
                          {data?.blogTitle}
                        </h2>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
}
