import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../theme/common.styles";
import zIndex from "@material-ui/core/styles/zIndex";

const style = makeStyles((theme) => ({
  popularVenueHead: {
    marginBottom: "24px",
  },
  popularVenueText: {
    ...commonStyles.title1,
    lineHeight: "30px",
    textTransform: "capitalize",
    color: "#212121",
    letterSpacing: "0.105882px",
  },
  viewAllBtn: {
    height: "40px",
    borderRadius: "12px",
    padding: "8px",
  },
  viewAll: {
    ...commonStyles.title3,
    lineHeight: "20px",
    color: "#ff4e00",
    textTransform: "uppercase",
  },
  rightArrow: {
    marginLeft: "8px",
  },
  hr: {
    margin: 0,
    color: "#98A0A2",
    border: "none",
    borderTop: "1px solid #EDF3FD",
  },
  blogContainer: {
    background: "#FFFFFF",
    margin: "15px 20px",
  },
  blogImageCard: {
    position: "relative",
    width: "auto",
    height: "auto",
    background: "#FFFFFF",
    // border: "1px solid #F2F2F2",
    borderRadius: "16px",
    marginRight: "16px",
  },
  blogCard: {
    overflowY: "hidden",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  linearBox: {
    left: 0,
    width: "100%",
    bottom: 0,
    height: "380px",
    position: "absolute",
    background:
      "linear-gradient(1deg, rgba(0, 0, 0, 0.80) 9.44%, rgba(0, 0, 0, 0.00) 41.53%)",
    borderRadius: "16px",
  },
  blogImage: {
    width: "380px",
    height: "380px",
    borderRadius: "16px",
    flexGrow: 0,
    objectFit: "cover",
  },
  blogImageTwo: {
    width: "700px",
    height: "340px",
    borderRadius: "16px",
    flexGrow: 0,
  },
  blogNum: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: "42px",
    color: "#fff",
    position: "absolute",
    bottom: "68px",
    left: "20px",
  },
  blogNumTwo: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: "42px",
    color: "#fff",
    position: "absolute",
    bottom: "70px",
    left: "20px",
  },
  blogText: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: "26px",
    color: "#fff",
    position: "absolute",
    bottom: "28px",
    left: "80px",
    padding: "0px 4px",
  },
  blogTextTwo: {
    bottom: "20px",
  },
  publishDate: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "12px",
    color: "#fff",
    position: "absolute",
    bottom: "92px",
    left: "80px",
  },
  publishDateTwo: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "12px",
    color: "#fff",
    position: "absolute",
    bottom: "90px",
    left: "80px",
  },

  "@media (max-width:550px)": {
    popularVenueHead: {
      padding: "0px",
      marginBottom:"16px",
    },
    popularVenueText: {
      fontSize: 15,
      fontWeight: 600,
      lineHeight: "16px",
      textTransform: "capitalize",
      color: "#212121",
    },
    viewAll: {
      fontSize: 12,
      fontWeight: 700,
    },
    hr: {
      margin: 0,
      color: "#98A0A2",
      border: "none",
      borderTop: "1px solid #EDF3FD",
    },
    blogContainer: {
      // margin: "16px",
      background: "#FDFDFD",
    },
    blogImageCard: {
      margin: "0 auto",
    },
    blogCard: {
      overflowY: "hidden",
      padding: "0px",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-moz-scrollbars": {
        display: "none",
      },
    },
    blogImage: {
      width: "270px",
      height: "250px",
      // objectFit:"cover",
      borderRadius: "16px",
      flexGrow: 0,
    },
    blogText: {
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "14px",
      color: "#fff",
      left: "48px",
      bottom: "34px",
    },
    bText: {
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "14px",
      color: "#fff",
      left: "60px",
      position: "absolute",
      bottom: "28px",
    },
    publishDate: {
      fontSize: 10,
      fontWeight: 500,
      lineHeight: "12px",
      color: "#fff",
      position: "absolute",
      bottom: "54px",
      left: "50px",
    },
    publishDateTwo: {
      fontSize: 10,
      fontWeight: 500,
      lineHeight: "12px",
      color: "#fff",
      position: "absolute",
      bottom: "58px",
      left: "60px",
    },
    blogNum: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: "28px",
      color: "#fff",
      position: "absolute",
      bottom: "42px",
      left: "6px",
    },
    blogNumTwo: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: "28px",
      color: "#fff",
      position: "absolute",
      bottom: "45px",
      left: "20px",
    },
    blogCard: {
      "& .control-dots": {
        textAlign: "center",
        bottom: "2px",
        margin: 0,
        "& li": {
          background: "#fff !important",
          opacity: "1 !important",
          outline: "none",
          boxShadow: "none !important",
          height: "6px !important",
          width: "6px !important",
          margin: "0 3px !important",
        },
        "& li.selected": {
          background: "#ff4e00 !important",
          height: "6px !important",
          width: "17px !important",
          borderRadius: "8px",
          opacity: "1 !important",
        },
      },
    },
  },
  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  alignCenter: {
    alignItems: "center",
  },
  flexCol: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  alignEnd: {
    alignItems: "end",
  },
}));

export default style;
