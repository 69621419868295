import React from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import useStyles from "./styles";
import VpccDesk from "./VpccDesk";
import MobileVpcc from "./MobileVpcc";

const KmVpcc = () => {
  const classes = useStyles();

  return <>{isMobile ? <MobileVpcc /> : <VpccDesk />}</>;
};

export default KmVpcc;
