export const deskKmGalleryData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-1.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-2.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-3.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-4.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-5.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-6.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-7.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-8.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-9.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-10.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-11.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-12.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-13.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-14.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-15.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-16.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-17.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-18.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/km-gl-19.png",
  },
];

export const mobileKmGalleryData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newVenueFball.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newVenueCricket.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newVenueBdmn.png",
  },
];