import React, { useState } from "react";
// import { Tabs } from "@mui/material";
// import { Tab } from "@mui/material";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";
import SportsVenue from "./SportsVenue";
import CricketChamps from "./CricketChamps";
import CricketCoaching from "./CricketCoaching";
import Events from "./Events";

export default function DeskCategory() {
  const classes = useStyles();
  const commClasses = commonStyles();

  const [activeTab, setActiveTab] = useState("first-tab");

  const tabsText = [
    {
      id: "first-tab",
      text: "Sports Venues",
      component: <SportsVenue />,
    },
    {
      id: "second-tab",
      text: "Cricket Champs (5-12 yrs)",
      component: <CricketChamps />,
    },
    {
      id: "third-tab",
      text: "Cricket Coaching (12+ yrs)",
      component: <CricketCoaching />,
    },
    {
      id: "fourth-tab",
      text: "Events Near You",
      component: <Events />,
    },
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // function CustomTabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && <div>{children}</div>}
  //     </div>
  //   );
  // }

  // CustomTabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired,
  // };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // }

  return (
    <>
      <div className={commClasses.mtb48lr120}>
        <div className={clsx(commClasses.fs24fw7, classes.categoryText)}>
          Categories
        </div>
        <div
          className={clsx(
            commClasses.flex,
            classes.topBorder,
            classes.scrollContainer
          )}
        >
          {/* <Tabs
            classes={{ indicator: classes.indicator, root: classes.scrollTabs }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              classes={{
                root: classes.topText,
                selected: classes.activeCta,
              }}
              label="Sports Venues"
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                root: classes.topText,
                selected: classes.activeCta,
              }}
              label="Cricket Champs (5-12 yrs)"
              {...a11yProps(1)}
            />
            <Tab
              classes={{
                root: classes.topText,
                selected: classes.activeCta,
              }}
              label="Cricket Coaching (12+ yrs)"
              {...a11yProps(2)}
            />
            <Tab
              classes={{
                root: classes.topText,
                selected: classes.activeCta,
              }}
              label="Events Near You"
              {...a11yProps(3)}
            />
          </Tabs> */}
          {tabsText?.map((tab) => (
            <div
              onClick={() => setActiveTab(tab?.id)}
              key={tab?.id}
              className={clsx(
                classes.topText,
                activeTab === tab?.id ? classes.activeCta : ""
              )}
            >
              {tab?.text}
            </div>
          ))}
          {/* <div className={clsx(classes.topText, classes.activeCta)}>
            Sports Venues
          </div>
          <div className={classes.topText}>Cricket Champs (5-12 yrs)</div>
          <div className={classes.topText}>Cricket Coaching (12+ yrs)</div>
          <div className={classes.topText}>Events Near You</div> */}
        </div>
        {/* <SportsVenue /> */}
        {tabsText?.find((tab) => tab?.id === activeTab)?.component}
        {/* <CustomTabPanel value={value} index={0}>
          <SportsVenue />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CricketChamps />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CricketCoaching />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Events />
        </CustomTabPanel> */}
      </div>
    </>
  );
}
