import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../../components/commonStyles";

const DeskCarouselCard = ({ carouselData }) => {
  const classes = useStyles();

  const commClasses = commonStyles();

  const textImageData = [
    {
      id:"first-image",
      mainText: "Book a sports venue near you",
      subText: "Discover an array of courts, turfs and play zones near you!",
      ctaText: "Explore Now",
      ctaIcon:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/right-arrow.png",
      imageUrl:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/new-km-h1.png",
      url:"https://www.khelomore.com/sports-venues",
      target:"_blank"
    },
    {
      id:"second-image",
      mainText: "Cricket Champs for Kids",
      age:"(5-12 yrs)",
      subText: "An exciting program introducing kids to cricket basics, teamwork, and fun on the field.",
      ctaText: "Try Now",
      ctaIcon:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/right-arrow.png",
      imageUrl:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/new-km-h2.png",
      url:"#section-one"
    },
    {
      id:"third-image",
      mainText: "Pro Cricket Coaching",
      age:"(12+ yrs)",
      subText: "Elevate skills, strategy, and performance with expert coaching tailored for young athletes.",
      ctaText: "Try Now",
      ctaIcon:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/right-arrow.png",
      imageUrl:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/new-km-h3.png",
        url:"#section-two",
    },
    {
      id:"fourth-image",
      mainText: "Explore Events Near You",
      subText: "Embark on a thrilling journey to uncover exciting events and experiences around you.",
      ctaText: "Explore Now",
      ctaIcon:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/right-arrow.png",
      imageUrl:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/new-km-h4.png",
        url:"https://www.khelomore.com/events/explore-events",
        target:"_blank"
    },
  ];

  return (
    <div className={clsx(classes.carouselBannerContainer,classes.caraouselArea)}>
      <Carousel
        autoPlay
        infiniteLoop
        showIndicators={textImageData?.length > 1}
        showStatus={false}
        showThumbs={false}
        interval={4000}
        showArrows={false}
      >
        {textImageData?.map((data, idx) => (
          <a
            target={data?.target}
            href={data?.url}
            className={clsx(commClasses.flex,commClasses.spaceBw)}
            key={idx}
          >
            <div className={commClasses.w45}>
              <h1 className={clsx(classes.headText)}>{data?.mainText} <span className={classes.age}>{data?.age}</span></h1>
              <h2 className={clsx(classes.subHead)}>{data?.subText}</h2>
              <button className={clsx(classes.ctaText,commClasses.flex,commClasses.centerAlign)}> 
                {data?.ctaText}{" "}
                <span>
                  <img className={classes.ctaIcon} src={data?.ctaIcon} alt="icon" />
                </span>
              </button>
            </div>
            <div>
              <img className={clsx(commClasses.br16,classes.heroImage)} style={{}} src={data?.imageUrl} alt="carousel-image" />
            </div>
          </a>
        ))}
      </Carousel>
    </div>
  );
};

export default DeskCarouselCard;
