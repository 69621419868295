import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../images/hamBurger-bg.png";


const style = makeStyles((theme) => ({
	header: {
		padding: "48px 120px",
		background: "#FFFFFF",
		// boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
		zIndex: 999,
		position: "sticky",
		top: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	kmLogo: {
		height: "38px",
	},
	storeBox: {
		marginRight: "24px"
	},
	appleStore: {
		marginRight: "8px",
		width: "110px",
		height: "38px"
	},
	account: {
		marginRight: "24px"
	},
	venue: {
		fontSize: "16px",
		lineHeight: "20px",
		letterSpacing: "0.4px",
		color: "#212121",
		fontWeight: "500",
		textTransform:"uppercase",
		whiteSpace:"nowrap"
	},
	registerCTA: {
		borderRadius: "24px",
		background: "#FF4E00",
		color:"#fff",
		padding:"16px 12px",
		marginLeft:"28px",
		fontSize:"14px",
		fontWeight:700,
	},
	marginLeft: {
		marginLeft: "28px"
	},
	newTag : {
		background: "linear-gradient(90deg, #2C89CD 0%, #9523BD 100%)",
		padding:"3px 4px",
		borderRadius:"8px",
		fontWeight: 700,
		fontSize: "10px",
		lineHeight: "8px",
		letterSpacing: "0.015em",
		color: "#FFFFFF",
		marginLeft:"4px",
		display:"inline-block",
		position:"relative",
		top:"-2px"
	},
	ctaIcon:{
		width:"20px",
		height:"14px",
		marginRight:8,
	},
	ctaText:{
		whiteSpace:"nowrap"
	},
	carouselArea: {
		width:"250px",
    "& .control-dots": {
			bottom:"-10px",
			paddingTop:"4px",
      "& li": {
        background: "#fff !important",
        opacity: "0.5 !important",
        outline: "none",
        boxShadow: "none !important",
        height: "6px !important",
        width: "6px !important",
        margin: "0 3px !important",
      },
      "& li.selected": {
        height: "6px !important",
        width: "17px !important",
				borderRadius:"8px",
        opacity: "1 !important",
      },
    },
  },
	"@media (max-width: 550px)": {
		header: {
			padding: "16px",
			background: "#FFFFFF",
			boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
			zIndex: 100,
			position: "sticky",
			top: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between"
		},
		kmLogo: {
			width: "108px",
			height:"28px"
		},
		hbIcon: {
			display: "block",
			width: "18px",
			height: "12px"
		},

		hamburgerContainer: {
			backgroundColor: "#DDEAFA",
			background:`url(${bgImage}) no-repeat`,
			backgroundPosition: "initial",
			zIndex:"99999999"
		}

	},
	flex: {
		display: "flex"
	},
	flexWrap: {
		flexWrap: "wrap"
	},
	spaceBw: {
		justifyContent: "space-between"
	},
	alignCenter: {
		alignItems: "center"
	},
	flexCol: {
		flexDirection: "column"
	},
	justifyCenter: {
		justifyContent: "center"
	},
	justifyEnd: {
		justifyContent: "end"
	},
	relative: {
		position: "relative"
	},
	absolute: {
		position: "absolute"
	},
	alignEnd: {
		alignItems: "end"
	},
}))

export default style; 