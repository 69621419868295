import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
	topBorder:{
		borderTop:"1px solid #eee",
		marginBottom:"32px",
	},
	popularVenueHead: {
		padding: "0px 24px 24px 0px"
	},
	popularVenueText: {
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "30px",
		letterSpacing: "0.105882px",
		textTransform: "capitalize",
		color: "#212121"
	},
	viewAllBtn: {
		height: "40px",
		background: "#fff",
		borderRadius: "12px",
		padding: "8px"
	},
	viewAll: {
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "16px",
		color: "#19a3ff",
		letterSpacing:"0.1px",
		textTransform:"uppercase"
	},
	rightArrow: {
		marginLeft: "8px"
	},
	hr: {
		margin: 0,
		color: "#98A0A2",
		border: "none",
		borderTop: "1px solid #EDF3FD",
	},
	trainingCard: {
		background: "#FFFFFF",
		padding: "24px 120px",
	},
	locationTag: {
		overflowY: "hidden",
		whiteSpace: "nowrap",
		marginLeft:"24px",
		msOverflowStyle: "none",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		"&::-moz-scrollbars": {
			display: "none",
		},
	},
	locationButtonActive: {
		border: "1px solid #19a3ff",
		padding: "10px",
		marginRight: "8px",
		background: "rgba(25, 163, 255, 8%)",
		borderRadius: "16px",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "12px",
		letterSpacing: "0.4px",
		color: "#19a3ff"
	},
	locationButton: {
		border: "none",
		padding: "10px",
		marginRight: "8px",
		background: "#FFFFFF",
		border: "1px solid #B3B3B3",
		borderRadius: "16px",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "12px",
		letterSpacing: "0.4px",
		color: "#424242",
	},
	venueCard: {
		overflowY: "hidden",
		// padding: "0px 8px 12px 8px",
		msOverflowStyle: "none",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		"&::-moz-scrollbars": {
			display: "none",
		},
	},
	listCardContainer: {
		width: "308px",
		height: "256px",
		background: "#FFFFFF",
		border: "1px solid #EEEEEE",
		borderRadius: "8px",
		padding: "8px",
		marginRight: "8px",
	},
	listCardHeadText: {
		fontWeight: 600,
		fontSize: "15px",
		lineHeight: "15px",
		letterSpacing: "0.15px",
		color: "#212121",
		whiteSpace:"nowrap"
	},
	listText: {
		fontWeight: 500,
		fontSize: "12px",
		lineHeight: "12px",
		letterSpacing: "0.4px",
		color: "#424242",
		marginTop:"8px",
	},
	rating: {
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "12px",
		letterSpacing: "0.4px",
		color: "#212121"
	},
	starIcon: {
		width: "8.5px",
		height: "8.5px",
		background: "#FFFFFF",
		marginRight: "2.5px"
	},
	review: {
		fontWeight: 500,
		fontSize: "12px",
		lineHeight: "12px",
		letterSpacing: "0.4px",
		color: "#424242",
		marginLeft: "6px"
	},
	favExclusive: {
		top: "36px",
		width: "100%",
		padding: "8px 8px"
	},
	exclusiveBox: {
		background: "#212121",
		mixBlendMode: "normal",
		borderRadius: "10px",
		width: "72px",
		height: "14.5px",
	},
	exclusive: {
		fontWeight: 600,
		fontSize: "7px",
		lineHeight: "6px",
		letterSpacing: "1px",
		textTransform: "uppercase",
		color: "#ffffff"
	},
	listImgBox: {
		width: "100%"
	},
	venueOfWeek: {
		width: "72px",
		height: "25px",
		top: "142px",
		bottom: "0px",
		left: "210px",
		right: "0",
		padding: "5px",
		textAlign: "center",
		margin: "0 auto",
	},
	venueOfWeekIcon: {
		width: "15px",
		height: "12px",
	},
	venueDetails: {
		whiteSpace: "nowrap",
		fontWeight: 600,
		fontSize: "10.5px",
		lineHeight: "10.5px",
		letterSpacing: "0.4px",
		color: "#212121",
		padding: "0px 6px"
	},
	venueOfWeekText: {
		fontWeight: 700,
		fontSize: "6px",
		lineHeight: "7px",
		letterSpacing: "1.2px",
		textTransform: "uppercase",
		color: "#FFFFFF",
		margin: "3px 0px 0px 3px"
	},
	venueImg: {
		width: "324px",
		height: "182px",
		objectFit: "cover",
		borderRadius: "8px",
		margin: "8px 0px"
	},
	venueSportIcon: {
		marginLeft: "5px"
	},
	trainingCardContainer: {
		width: "340px",
		background: "#FFFFFF",
		borderRadius: "16px",
		// padding: "8px",
		marginRight: "8px",
	},
	ageGroup: {
		display: "inline-block",
		height: "20px",
		width:"auto",
		borderRadius: "4px",
		background: "#FF4E00",
		padding: "4px",
		top:"16px",
		bottom: "22px",
		left: "9px"
	},
	liveBatch: {
		display: "inline-block",
		height: "20px",
		width:"auto",
		borderRadius: "8px",
		background: "#FFF",
		padding: "4px",
		top:"20px",
		bottom: "22px",
		left: "9px",
	},
	groupIcon: {
		width: "12px",
		height: "12px",
		marginRight: "4px",
		marginBottom:"2.75px",
	},
	groupText: {
		fontWeight: 600,
		fontSize: "10px",
		lineHeight: "10px",
		letterSpacing: "1px",
		textTransform: "uppercase",
		color: "#FFFFFF",
	},
	liveText:{
		fontWeight: 600,
		fontSize: "10px",
		lineHeight: "10px",
		letterSpacing: "1px",
		textTransform: "uppercase",
		color: "#212121",
	},
	percentage: {
		display: "inline-block",
		width:"auto",
		height: "20px",
		borderRadius: "6px",
		background: "#FFBB33",
		padding: "4px",
	},
	percentageText: {
		fontWeight: 600,
		fontSize: "10px",
		lineHeight: "10px",
		textTransform: "uppercase",
		color: "#000000",
		letterSpacing:"1.2px"
	},
	mRight:{
		marginRight:"6px",
	},
	percentageIcon:{
		marginTop:"-2px"
},
	"@media (max-width:550px)": {
		popularVenueHead: {
			padding: "0px 0px 10px 0px"
		},
		popularVenueText: {
			fontWeight: 600,
			fontSize: "13px",
			lineHeight: "13px",
			textTransform: "capitalize",
			color: "#212121"
		},
		hr: {
			margin: 0,
			color: "#98A0A2",
			border: "none",
			borderTop: "1px solid #EDF3FD",
		},
		locationTag: {
			overflowY: "hidden",
			marginLeft:"0px",
			whiteSpace: "nowrap",
			padding: "0px 0px 16px 0px",
			msOverflowStyle: "none",
			scrollbarWidth: "none",
			"&::-webkit-scrollbar": {
				display: "none",
			},
			"&::-moz-scrollbars": {
				display: "none",
			},
		},
		locationButtonActive: {
			border: "1px solid #19a3ff",
			padding: "10px",
			marginRight: "8px",
			background: "rgba(25, 163, 255, 8%)",
			borderRadius: "16px",
			fontWeight: 600,
			fontSize: "12px",
			lineHeight: "12px",
			letterSpacing: "0.4px",
			color: "#19a3ff"
		},
		trainingCard: {
			background: "#FFFFFF",
			margin: "0px",
			padding:"0px 16px"
		},
		locationButton: {
			border: "none",
			padding: "10px",
			marginRight: "8px",
			background: "#FFFFFF",
			border: "1px solid #eee",
			borderRadius: "16px",
			fontWeight: 600,
			fontSize: "12px",
			lineHeight: "12px",
			letterSpacing: "0.4px",
			color: "#424242",
		},
		venueCard: {
			overflowY: "hidden",
			padding: "0px",
			msOverflowStyle: "none",
			scrollbarWidth: "none",
			"&::-webkit-scrollbar": {
				display: "none",
			},
			"&::-moz-scrollbars": {
				display: "none",
			},
		},
		listCardContainer: {
			width: "308px",
			height: "256px",
			background: "#FFFFFF",
			border: "1px solid #EEEEEE",
			borderRadius: "8px",
			padding: "8px",
			marginRight: "8px",
		},
		listCardHeadText: {
			fontWeight: 600,
			fontSize: "14px",
			lineHeight: "15px",
			color: "#212121",
			whiteSpace:"nowrap",
			width: "280px",
    	overflow: "hidden"
		},
		rating: {
			fontWeight: 600,
			fontSize: "12px",
			lineHeight: "12px",
			letterSpacing: "0.4px",
			color: "#212121"
		},
		starIcon: {
			width: "8.5px",
			height: "8.5px",
			background: "#FFFFFF",
			marginRight: "2.5px"
		},
		percentageIcon:{
			marginTop:"-2.25px"
		},
		review: {
			fontWeight: 500,
			fontSize: "12px",
			lineHeight: "12px",
			letterSpacing: "0.4px",
			color: "#424242",
			marginLeft: "6px"
		},
		exclusiveBox: {
			background: "#212121",
			mixBlendMode: "normal",
			borderRadius: "10px",
			width: "72px",
			height: "14.5px",
		},
		exclusive: {
			fontWeight: 600,
			fontSize: "7px",
			lineHeight: "6px",
			letterSpacing: "1px",
			textTransform: "uppercase",
			color: "#ffffff"
		},
		listImgBox: {
			width: "100%"
		},
		venueOfWeek: {
			width: "72px",
			height: "25px",
			top: "142px",
			bottom: "0px",
			left: "210px",
			right: "0",
			padding: "5px",
			textAlign: "center",
			margin: "0 auto",
		},
		venueOfWeekIcon: {
			width: "15px",
			height: "12px",
		},
		venueDetails: {
			whiteSpace: "nowrap",
			fontWeight: 600,
			fontSize: "10.5px",
			lineHeight: "10.5px",
			letterSpacing: "0.4px",
			color: "#212121",
			padding: "0px 6px"
		},
		venueOfWeekText: {
			fontWeight: 700,
			fontSize: "6px",
			lineHeight: "7px",
			letterSpacing: "1.2px",
			textTransform: "uppercase",
			color: "#FFFFFF",
			margin: "3px 0px 0px 3px"
		},
		venueImg: {
			width: "292px",
			height: "168.5px",
			objectFit: "cover",
			borderRadius: "8px",
			marginTop: "8px",
			marginBottom:"0px"
		},
		venueSportIcon: {
			marginLeft: "5px"
		},
		trainingCardContainer: {
			width: "308px",
			background: "#FFFFFF",
			borderRadius: "16px",
			marginRight: "8px",
		},
		groupText: {
			fontWeight: 600,
			fontSize: "10px",
			lineHeight: "10px",
			letterSpacing: "1px",
			textTransform: "uppercase",
			color: "#FFFFFF",
		},
		viewAll:{
			fontWeight: 700,
			fontSize: "10px",
			lineHeight: "12px",
			letterSpacing: "0.1px",
		},
	},
	flex: {
		display: "flex"
	},
	spaceBw: {
		justifyContent: "space-between"
	},
	justifyEnd: {
		justifyContent: "end"
	},
	alignCenter: {
		alignItems: "center"
	},
	flexCol: {
		flexDirection: "column"
	},
	justifyCenter: {
		justifyContent: "center"
	},
	relative: {
		position: "relative"
	},
	absolute: {
		position: "absolute"
	},
	alignEnd: {
		alignItems: "end"
	},
}));

export default style;

