import { React, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles"
import right_Arrow from "../../icons/desRightArrow.svg";


export default function DeskTrainingVenue({activeMode,modes, handleTrainingData}) {
  const classes = useStyles();

  const [isActive, setActive] = useState("Bengaluru");

  const isToggle = () => {
    setActive(!isActive);
  }



  return (
    <>
      <div className={clsx(classes.flex, classes.spaceBw, classes.popularVenueHead)}>
        <div className={clsx(classes.flex, classes.alignCenter)}>
          <h2 className={clsx(classes.popularVenueText)}>Join the pickleball wave near you!</h2>
          <div className={clsx(classes.locationTag)}>
            {modes?.map((mode,idx) => (
              <button key={idx} onClick={() => handleTrainingData(mode)} className={clsx(mode === activeMode ? classes.locationButtonActive : classes.locationButton)}>{mode}</button>
            ))}
          </div>
        </div>
        <div className={clsx(classes.viewAllBtn, classes.flex, classes.alignCenter, classes.justifyCenter)}>
          <a target="blank" className={clsx(classes.viewAll, classes.flex, classes.alignCenter)} href="https://www.khelomore.com/sports-venues/mumbai?sports=pickleball">View all</a>
        </div>
      </div>

    </>
  )
}