import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import { deskVenueCardData } from "../jsonData/newVenueCard";
import { TRENDING_POPULAR_BANNER } from "../../gqlOperations/queries";


import DeskVenueCards from "./DeskVenueCards";
import MobileVenueCards from "./MobileVenueCards";

export default function VenueCards() {
  const classes = useStyles();

  const { loading, error, data } = useQuery(TRENDING_POPULAR_BANNER);

  const cardData = deskVenueCardData?.map((details) => details);

  return (
    <>
      <DeskVenueCards cardData={cardData} loading={loading} />
    </>
  );
}
