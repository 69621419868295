import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import right_Arrow from "../../icons/desRightArrow.svg";
import BlogLoader from "../skeletonLoader/BlogLoader";
import commonStyles from "../commonStyles";

export default function DeskBlog({ data, loading }) {
  const classes = useStyles();
  const commClasses = commonStyles();
  return (
    <>
      <div className={clsx(commClasses.mtb24lr120)}>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.popularVenueHead,
            classes.alignCenter
          )}
        >
          <h2 className={clsx(classes.popularVenueText)}>Blogs and Articles</h2>
          <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              target="_blank"
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://blog.khelomore.com/"
            >
              View all{" "}
            </a>
          </div>
        </div>
        <div className={clsx(classes.flex, classes.blogCard)}>
          {loading ? (
            <BlogLoader />
          ) : (
            // data?.map((details,idx) =>(
            <div className={classes.flex}>
              <a
                className={clsx(classes.blogImageCard)}
                target="_blank"
                href="https://blog.khelomore.com/kishore-jena-and-neeraj-chopra/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://cdn-ilckmjl.nitrocdn.com/MQFWtjdgKqDgzrenAjmMgXQMbtBBmJeV/assets/images/optimized/blog.khelomore.com/wp-content/uploads/2024/07/2T05DEP-scaled-1-2048x1366.jpg"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNum}>01</div>
                  <div>
                    <div className={classes.publishDate}>
                      23rd July 2024 | 2 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                    Rising Stars: Kishore Jena and Neeraj Chopra – India’s Javelin
                    </h2>
                  </div>
                </div>
              </a>
              <a
                className={clsx(classes.blogImageCard)}
                target="_blank"
                href="https://blog.khelomore.com/empowering-innings-the-rise-of-womens-cricket-worldwide/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://blog.khelomore.com/wp-content/uploads/2024/07/new-wem-2048x929.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNumTwo}>02</div>
                  <div>
                    <div className={classes.publishDateTwo}>
                      11th July 2024 | 2 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                    Empowering Innings: The Rise of Women’s Cricket Worldwide
                    </h2>
                  </div>
                </div>
              </a>
              <a
                className={clsx(classes.blogImageCard)}
                target="_blank"
                href="https://blog.khelomore.com/and-that-was-the-final/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://blog.khelomore.com/wp-content/uploads/2024/05/ipl-final.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNum}>03</div>
                  <div>
                    <div className={classes.publishDate}>
                      28th May 2024 | 1 min read
                    </div>
                    <h2 style={{bottom:"54px", left:"76px"}} className={clsx(classes.blogText)}>
                    And that was the Final                             
                    </h2>
                  </div>
                </div>
              </a>
              <a
                className={clsx(classes.blogImageCard)}
                target="_blank"
                href="https://blog.khelomore.com/cricketing-glory-indias-world-cup-journey-1975-2023/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://blog.khelomore.com/wp-content/uploads/2024/05/wc-journey-2048x936.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNum}>04</div>
                  <div>
                    <div className={classes.publishDate}>
                      17th May 2024 | 2 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                    Cricketing Glory: India’s World Cup Journey (1975-2023)
                    </h2>
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
