import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  topBorder: {
    borderBottom: "1px solid #eee",
    width:"auto",
  },
  topText: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
    lineHeight: "20px !important",
    letterSpacing: "0.4px !important",
    whiteSpace: "nowrap !important",
    color: "#757575 !important",
    margin: "16px 32px 0px 0px !important",
    padding: "0px 0px 16px 0px !important",
    textTransform:"initial !important",
    cursor:"pointer"
  },
  scrollTabs:{
    overflow:"unset !important"
  },
  activeCta: {
    color: "#ff4e00 !important",
    borderBottom: "4px solid #ff4e00",
    paddingBottom: 16,
  },
  indicator:{
    backgroundColor:"#ff4e00 !important",
    height:"4px !important",
  },
  catCard: {
    width: 440,
    height:"auto"
  },
  venueImg: {
    width: 127,
    height: 154,
  },
  bookNowCta: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    gap: "8px",
    width: "max-content",
    height: 28,
    background: "rgba(25, 163, 255, 0.08)",
    border: "1px solid #19A3FF",
    borderRadius: "16px",
    color:"#19a3ff",
    fontSize:15,
    fontWeight:700,
    lineHeight:"12px",
    letterSpacing:"0.4px",
    whiteSpace:"nowrap",
    marginLeft:16,
  },
  bottomBorder:{
    borderBottom: "2px solid #eee",
    paddingBottom:12,
    width:"100%",
  },
  scrollContainer: {
    overflowY: "hidden",
    // whiteSpace: "nowrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  mL0:{
    marginLeft: "0px !important"
  },
  cityName:{
    fontSize:"16px !important",
    lineHeight:"16px",
    letterSpacing:"0.3px"
  },
  "@media (max-width:550px)": {
    categoryText:{
      fontSize:15,
      lineHeight:"15px",
      letterSpacing:"0.3px"
    },
    topText:{
      fontSize:"15px !important",
      lineHeight:"15px !important",
      letterSpacing:"0.1px !important",
      margin:"8px 16px 0px 0px !important",
      padding:"0px 0px 16px 0px !important"
    },
    indicator:{
      height:"2px !important",
    },
    bookText:{
      fontSize:"12px !important",
      lineHeight:"12px",
      letterSpacing:"0.3px",
      marginTop:12,
    },
    cityName:{
      fontSize:"12px !important",
      lineHeight:"12px",
      letterSpacing:"0.3px"
    },
    mT12:{
      marginTop:"12px"
    },
    mB12:{
      marginBottom:"12px"
    },
    venueImg:{
      width:94,
      height:115,
      marginTop:12,
    },
    tc:{
      fontSize:8,
      letterSpacing:"0.3px"
    },
    bookNowCta:{
      height:20,
      fontSize:12,
      marginBottom:12,
    },
    catCard:{
      display:"flex",
      alignItems:"center",
      padding:"0px 12px"
    }
  },
}));

export default style;
