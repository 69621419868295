import { React, useState, useRef } from "react";
import clsx from "clsx";
// import { motion } from "framer-motion";
import SectionWithScrollButtons from "../Common/SectionWithScrollButtons";
import useStyles from "./styles";
import commonStyles from "../commonStyles";
// import transitions from "@material-ui/core/styles/transitions";
// import { duration } from "@mui/material";

export default function DeskPartners() {
  const classes = useStyles();
  const commClasses = commonStyles();
  const scrl = useRef(null);

  const isMobile = window.innerWidth < 800;

  const cardData = [
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-one.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-two.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-three.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-four.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-five.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-six.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-seven.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-eight.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-nine.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-elev.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-twlv.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-thirteen.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-fourteen.png",
    },
    {
      src: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/pp-ten.png",
    },
  ];

  const cardList = [...cardData, ...cardData, ...cardData, ...cardData];

  return (
    <>
      <div className={clsx(commClasses.mtb24lr120, classes.mr0)}>
        <div className={classes.kmGallery}>Our Power Partners</div>
        {/* <SectionWithScrollButtons
        isBg={true}
        onClickRight={() => {
          scrl.current.scrollLeft += 250;
        }}
        onClickLeft={() => {
          scrl.current.scrollLeft -= 250;
        }}
      > */}
        {/* <div style={{width:"100%", overflow:"hidden"}} className={classes.pdlr120}>
          <motion.div
            style={{width:"100%"}}
            animate={{ x: isMobile ? ["-10%", "-720%"] : ["-5%", "-180%"] }}
            transition={{ repeat: Infinity,  duration: isMobile ? 40 : 50, ease: "linear" }}
            className={clsx(classes.flex, classes.imageContainer)}
          >
            {cardList?.map((details, idx) => (
              <div key={idx}>
              <img
                className={classes.ratingImg}
                src={details?.src}
                alt="partners image"
              />
              </div>
            ))}
          </motion.div>
        </div> */}

         <div
          className={clsx(classes.imageContainer,classes.scrollContainer)}
          id="content"
          ref={scrl}
        >
          {cardData?.map((details, idx) => (
            <div key={idx}>
            <img
              className={classes.ratingImg}
              src={details?.src}
              alt="partners image"
            />
            </div>
          ))}
        </div> 
        {/* </SectionWithScrollButtons> */}
      </div>
    </>
  );
}
