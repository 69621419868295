import React from "react";
import { useQuery } from "@apollo/client";
import { MOBILE_BANNER } from "../../gqlOperations/queries";
import useStyles from "./styles";
import MobAtHomeLoader from "../skeletonLoader/MobAtHomeLoader";



export default function MobileAppBanner(){
    const classes = useStyles();
  const {loading, error , data} = useQuery(MOBILE_BANNER);
  const imgData = data?.mobileBanners.data.map((detail,idx)=> detail.attributes.imageURL);


    return(
        <>
        <div className={classes.homeBannerCard}>
            { loading ? <MobAtHomeLoader /> :
            <a target="_blank" href="https://linktr.ee/khelo_more">
                <img className={classes.homeBanner} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newMobAppBanner.png" alt="app banner" />
            </a>
            }
        </div>
        </>
    )
}