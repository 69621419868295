import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function CricketChamps() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div className={clsx(commClasses.flex, classes.scrollContainer)}>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-wings.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText,
                  commClasses.noWrap
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mB4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  Wings Sport Arena
                </strong>
                <br />
                Bandra (West)
              </h4>
              <span className={classes.bottomBorder}></span>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                6 BATCHES LIVE
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.centerAlign,
                  commClasses.spaceBw
                )}
              >
                <a href="https://www.khelomore.com/sports-coaching/society/khelomore-cricket-champs-at-wings-sports-center-/mumbai/355" target="_blank">
                  <button className={clsx(classes.bookNowCta,classes.mL0)}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24,
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-vpcc-3.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText,
                  commClasses.noWrap
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mB4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  Emma Sports Academy
                </strong>
                <br />
                Powai, Andheri (East)
              </h4>
              <span className={classes.bottomBorder}></span>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                10 BATCHES LIVE
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.centerAlign,
                  commClasses.spaceBw
                )}
              >
                <a href="https://www.khelomore.com/sports-coaching/society/khelomore-cricket-champs-at-emma-sports-academy/mumbai/60" target="_blank">
                  <button className={clsx(classes.bookNowCta,classes.mL0)}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-s3.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText,
                  commClasses.noWrap
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mB4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  Gallant Sports Arena
                </strong>
                <br />
                Juhu, Vile Parle (West)
              </h4>
              <span className={classes.bottomBorder}></span>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                9 BATCHES LIVE
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.centerAlign,
                  commClasses.spaceBw
                )}
              >
                <a href="https://www.khelomore.com/sports-coaching/society/khelomore-cricket-champs-at-gallant-sports-/mumbai/351" target="_blank">
                  <button className={clsx(classes.bookNowCta,classes.mL0)}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-s4.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16,
                commClasses.noWrap
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mB4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  St Francis
                </strong>
                <br />
                Mumbai
              </h4>
              <span className={classes.bottomBorder}></span>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                26 BATCHES LIVE
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.centerAlign,
                  commClasses.spaceBw
                )}
              >
                <a href="https://www.khelomore.com/sports-coaching/society/st-francis/mumbai/370" target="_blank">
                  <button className={clsx(classes.bookNowCta,classes.mL0)}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-s5.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16,
                commClasses.noWrap
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mB4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  PJ Hindu Gymkhana
                </strong>
                <br />
                Marine Lines
              </h4>
              <span className={classes.bottomBorder}></span>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                12 BATCHES LIVE
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.centerAlign,
                  commClasses.spaceBw
                )}
              >
                <a href="https://www.khelomore.com/sports-coaching/society/pj-hindu-gymkhana/mumbai/369" target="_blank">
                  <button className={clsx(classes.bookNowCta,classes.mL0)}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
