import React from "react";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import useStyles from "./styles";
import { TRAINING_BY_COACHES } from "../../gqlOperations/queries";
import CardLoader from "../skeletonLoader/CardLoader";
import commonStyles from "../commonStyles";

export default function KmCricketChamps() {
  const classes = useStyles();
  const commClasses = commonStyles();
  const { loading, error, sdata } = useQuery(TRAINING_BY_COACHES);

  const data = [
    {
      nameOfTheCamp: "Wings Sports Arena",
      cardURL:
        "https://www.khelomore.com/sports-coaching/society/khelomore-cricket-champs-at-wings-sports-center-/mumbai/355",
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-b1.png",
      address: "Bandra (West)",
      liveBatches: 6,
    },
    {
      nameOfTheCamp: "Emma Sports Academy",
      cardURL:
        "https://www.khelomore.com/sports-coaching/society/khelomore-cricket-champs-at-emma-sports-academy/mumbai/60",
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-b2.png",
      address: "Powai, Andheri (East)",
      liveBatches: 10,
    },
    {
      nameOfTheCamp: "Gallant Sports Arena",
      cardURL:
        "https://www.khelomore.com/sports-coaching/society/khelomore-cricket-champs-at-gallant-sports-/mumbai/351",
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-b3.png",
      address: "Juhu, Vile Parle (West)",
      liveBatches: 9,
    },
    {
      nameOfTheCamp: "St Francis",
      cardURL:
        "https://www.khelomore.com/sports-coaching/society/st-francis/mumbai/370",
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-b4.png",
      address: "Mumbai",
      liveBatches: 26,
    },
    {
      nameOfTheCamp: "PJ Hindu Gymkhana",
      cardURL:
        "https://www.khelomore.com/sports-coaching/society/pj-hindu-gymkhana/mumbai/369",
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-b5.png",
      address: "Marine Lines",
      liveBatches: 12,
    },
  ];

  return (
    <>
      <div className={commClasses.mtb48lr120}>
        <h2 className={clsx(classes.popularVenueText,commClasses.mB24)}>KheloMore Cricket Champs (5 to 12 years)</h2>
        <div
          className={clsx(
            classes.venueCard,
            classes.flex,
          )}
        >
          {loading ? (
            <CardLoader />
          ) : (
            data?.map((details, idx) => (
              <a key={idx} href={details.cardURL} target="_blank">
                <div className={clsx(classes.trainingCardContainer)}>
                  <div
                    className={clsx(
                      classes.listCardTextBox,
                      classes.flex,
                      classes.spaceBw,
                      classes.alignEnd
                    )}
                  >
                    <div
                      className={clsx(
                        classes.listCardTextArea,
                        classes.flex,
                        classes.flexCol
                      )}
                    >
                      <h3 className={clsx(classes.listCardHeadText)}>
                        {details?.nameOfTheCamp}
                      </h3>
                      <small className={clsx(classes.listText)}>
                        {/* { details?.academyName ? `Academy: ${details?.academyName}` :  `Coach: ${details?.coachName}`} */}
                        {details?.address}
                      </small>
                    </div>
                    {/* <div className={clsx(classes.flex)}>
                  <p className={clsx(classes.rating)}>
                    <img
                      className={clsx(classes.starIcon)}
                      src={starIcon}
                      alt="icon"
                    />
                    {details?.overallRating}
                  </p>
                  <small className={clsx(classes.review)}>
                    ({details?.numberOfRatings})
                  </small>
                </div> */}
                  </div>
                  <div className={clsx(classes.listCard, classes.relative)}>
                    <div className={clsx(classes.listImgBox)}>
                      <img
                        className={clsx(classes.venueImg)}
                        src={details.imageURL}
                        alt="image"
                      />
                      <div
                        className={clsx(
                          classes.flex,
                          classes.absolute,
                          classes.spaceBw,
                          classes.favExclusive,
                          classes.alignCenter
                        )}
                      >
                        {details?.level ? (
                          <div
                            className={clsx(
                              classes.percentage,
                              classes.flex,
                              classes.alignCenter
                            )}
                          >
                            <p className={clsx(classes.percentageText)}>
                              {details?.level}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.liveBatch,
                        classes.absolute,
                        classes.flex,
                        classes.alignCenter
                      )}
                    >
                      <p
                        className={clsx(
                          classes.liveText,
                          classes.flex,
                          classes.alignCenter
                        )}
                      >
                        {details?.liveBatches} BATCHES LIVE
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ))
          )}
        </div>
      </div>
    </>
  );
}
