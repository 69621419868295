import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function CricketCoaching() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div
        className={clsx(
          commClasses.flex,
          commClasses.spaceBw,
          classes.scrollContainer
        )}
      >
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/vpcc-wings.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16,
                commClasses.noWrap
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mT4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  VPCC Wings
                </strong>
                <br />
                <p className={classes.bottomBorder}>Bandra</p>
              </h4>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                ₹4000 onwards
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.bottomAlign,
                  commClasses.spaceBw
                )}
              >
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.noWrap,
                    classes.tc
                  )}
                >
                  *T&C Apply
                </span>
                <a target="_blank" href="https://www.khelomore.com/sports-coaching/society/vpcc-wings-sports-centre/mumbai/357">
                  <button className={classes.bookNowCta}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-vpcc-2.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16,
                commClasses.noWrap
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText,
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mT4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  VPCC Don Bosco
                </strong>
                <br />
                <p className={classes.bottomBorder}> Matunga</p>
              </h4>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                ₹4000 onwards
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.bottomAlign,
                  commClasses.spaceBw
                )}
              >
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.noWrap,
                    classes.tc
                  )}
                >
                  *T&C Apply
                </span>
                <a target="_blank" href="https://www.khelomore.com/sports-coaching/society/-don-bosco-cricket-academy/mumbai/358">
                  <button className={classes.bookNowCta}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-vpcc-3.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16,
                commClasses.noWrap
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mT4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  Saraswati Sports
                </strong>
                <br />
                <p className={classes.bottomBorder}> Malad</p>
              </h4>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                ₹2500 onwards
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.bottomAlign,
                  commClasses.spaceBw
                )}
              >
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.noWrap,
                    classes.tc
                  )}
                >
                  *T&C Apply
                </span>
                <a target="_blank" href="https://www.khelomore.com/sports-coaching/society/-saraswati-sports-complex/mumbai/360">
                  <button className={classes.bookNowCta}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.catCard,
            commClasses.p12,
            commClasses.b1EEE,
            commClasses.br16,
            commClasses.mT24,
            commClasses.mR24
          )}
        >
          <div className={clsx(commClasses.flex, commClasses.startAlign)}>
            <div>
              <img
                className={clsx(classes.venueImg)}
                src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/cc-vpcc-3.png"
                alt="image"
              />
            </div>
            <div
              className={clsx(
                commClasses.flex,
                commClasses.flexCol,
                commClasses.mL16,
                commClasses.noWrap
              )}
            >
              <h4
                className={clsx(
                  commClasses.fs16fw6,
                  commClasses.gray,
                  classes.bookText
                )}
              >
                <strong
                  className={clsx(
                    commClasses.fs24fw7,
                    commClasses.fw600,
                    commClasses.black21,
                    commClasses.mT4,
                    commClasses.displayInlineBlock,
                    classes.cityName
                  )}
                >
                  VPCC Sarjapur
                </strong>
                <br />
                <p className={classes.bottomBorder}>Bengaluru</p>
              </h4>
              <span
                className={clsx(
                  commClasses.fs15fw6,
                  commClasses.black21,
                  commClasses.displayInlineBlock,
                  commClasses.mT24,
                  commClasses.mB24,
                  classes.cityName,
                  classes.mT12,
                  classes.mB12
                )}
              >
                ₹3000 onwards
              </span>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.bottomAlign,
                  commClasses.spaceBw
                )}
              >
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.noWrap,
                    classes.tc
                  )}
                >
                  *T&C Apply
                </span>
                <a target="_blank" href="https://www.khelomore.com/sports-coaching/society/vpcc-sarjapur/bengaluru/366">
                  <button className={classes.bookNowCta}>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
