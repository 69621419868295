export const deskVenueCardData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-box-cr-2.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=cricket",
    // tag:"Popular",
    sportName:"Box Cricket",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-football-new.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=football",
    sportName:"Football",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-badminton-2.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=badminton",
    sportName:"Badminton",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-pickleball.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=pickleball",
    tag:"New",
    sportName:"Pickleball",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-cricket-nets.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=cricket-nets",
    sportName:"Cricket Nets",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-tennis.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/bengaluru?sports=tennis",
    sportName:"Tennis",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/venue-tt.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/bengaluru?sports=table_tennis",
    sportName:"Table Tennis",
  },
];