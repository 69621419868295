import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../theme/common.styles";
import bgIcon from "../../icons/select-arrow.svg";

const style = makeStyles((theme) => ({
  scrollable: {
    width: "377px",
    height: "56px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #C9CDD5",
    borderRadius: "8px",
    margin: "24px 0px 24px -10px",
    color: "#424242",
    ...commonStyles.title3,
    lineHeight: "22px",
    paddingLeft: "50px",
    appearance: "none",
    outline: "none",
    backgroundPosition: "calc(100% - 25px) center !important",
    background: `url(${bgIcon}) no-repeat`,
    "&::-webkit-appearance": {
      display: "none",
    },
    "&::-moz-appearance": {
      display: "none",
    },
    "&::-ms-appearance": {
      display: "none",
    },
  },

  locationIcon: {
    position: "relative",
    left: "18px",
  },
  input: {
    width: "377px",
    height: "46px",
    background: "#FFFFFF",
    border: "1px solid #eee",
    borderRadius: "15px",
    marginLeft: "-18px",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "22px",
    paddingLeft: "50px",
    outline: "none",
    color: "#424242",
    "&::placeholder": {
      color: "#A9A7A7",
    },
  },
  searchIcon: {
    position: "relative",
    left: "15px",
    color: "lightgray",
  },
  homeTraining: {
    height: "52px",
    background: "#FFFFFF",
    border: "1px solid #D8DEE8",
    borderRadius: "8px",
    margin: "20px 0px 16px 0px",
    color: "#424242",
    ...commonStyles.title3,
    paddingLeft: "30px",
  },
  searchIcon: {
    position: "relative",
    top: "15px",
    left: "22px",
    width: "14px",
    height: "14px",
  },
  serachInput: {
    // margin: "24px 0px 0px -8px",
  },
  searchBtn: {
    padding: "12px 16px",
    background: "#ff4e00",
    borderRadius: "14px",
    width: "100%",
    // margin: "50px 0px 40px 0px",
    cursor: "default",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "17px",
    marginLeft: "12px",
    height: "46px",
  },
  activeSearchBtn: {
    background: "#ff4e00",
    cursor: "pointer",
  },
  searchBtnText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "16px",
    color: "#FFFFFF",
    cursor: "default",
  },

  activeSearchBtnText: {
    cursor: "pointer",
  },
  bottomLine: {
    width: "70%",
    display: "inline-block",
    borderBottom: "2px solid #FF4E00",
    paddingBottom: "10px",
  },
  homeTrainingBtn: {
    height: "46px",
    width: "265px",
    background: "#FFFFFF",
    border: "1px solid #eee",
    borderRadius: "14px",
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "17px",
    color: "#424242",
    mixBlendMode: "normal",
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deskBtn: {
    margin: "0 auto",
  },
  homeTrainingActiveBtn: {
    background: "#FF4E00",
    height: "46px",
    width: "265px",
    border: "1px solid #eee",
    borderRadius: "14px",
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "17px",
    color: "#fff",
    mixBlendMode: "normal",
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carouselTopText: {
    fontWeight: 600,
    fontSize: "42px",
    lineHeight: "100%",
    textTransform: "capitalize",
    color: "#212121",
    margin: "140px 0px 15px 0px",
  },
  carouselBottomText: {
    fontWeight: 700,
    fontSize: "52px",
    lineHeight: "100%",
    color: "#212121",
    marginRight: "25px",
  },
  searchContainer: {
    background: "#f3f8fb",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    width: "580px",
    height: "auto ",
    padding: "0px 24px 24px 24px",
    position: "relative",
    bottom: 0,
    // right:50,
  },
  topText: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0.1px",
    color: "#000",
  },
  carouselActiveBtn: {
    ...commonStyles.title3,
    fontWeight: "700",
    lineHeight: "100%",
    textAlign: "center",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "#FF4E00",
    height: 55,
    display: "inline-flex",
    alignItems: "center",
  },
  carouselBtn: {
    ...commonStyles.title3,
    fontWeight: "700",
    lineHeight: "100%",
    textAlign: "center",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "#424242",
    height: 55,
    display: "inline-flex",
    alignItems: "center",
  },
  mLeft: {
    marginLeft: "50px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  socialArea: {
    padding: "48px 120px",
  },
  socialIcon: {
    marginRight: 24,
  },
  carouselBannerContainer: {
    padding: "0px 120px 48px 120px",
  },
  carouselBannerDiv: {
    display: "block",
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },
  headText: {
    fontSize: 72,
    fontWeight: "600",
    lineHeight: "86px",
    letterSpacing: "0.4px",
    color: "#000",
    marginBottom: 32,
    whiteSpace: "break-spaces",
    textAlign: "left",
  },
  age: {
    fontSize: 56,
    fontWeight: "600",
    lineHeight: "67px",
    letterSpacing: "0.4px",
    color: "#000",
    marginBottom: 32,
    whiteSpace: "break-spaces",
  },
  subHead: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "32px",
    letterSpacing: "0.4px",
    color: "#757575",
    marginBottom: 80,
    textAlign: "left",
  },
  ctaText: {
    fontSize: 32,
    fontWeight: "700",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    color: "#fff",
    backgroundColor: "#ff4e00",
    borderRadius: 32,
    padding: 24,
  },
  ctaIcon: {
    width: 20,
    height: 12,
    marginLeft: 8,
    objectFit: "contain",
  },
  heroImage: {
    height: 428,
    objectFit: "contain",
    marginBottom: "32px",
  },
  caraouselArea: {
    "& .control-dots": {
      textAlign: "right",
      bottom: "8px",
      right: "15%",
      margin: 0,
      "& li": {
        background: "#212121 !important",
        opacity: "1 !important",
        outline: "none",
        boxShadow: "none !important",
        height: "6px !important",
        width: "6px !important",
        margin: "0 3px !important",
      },
      "& li.selected": {
        background: "#ff4e00 !important",
        height: "6px !important",
        width: "17px !important",
        borderRadius: "8px",
        opacity: "1 !important",
      },
    },
  },
  /* Small desktop */
  "@media (min-width: 900px) and (max-width: 1239px)": {
    headText: {
      fontSize: 42,
      fontWeight: "600",
      lineHeight: "54px",
      letterSpacing: "0.4px",
      color: "#000",
      marginBottom: 32,
      whiteSpace: "break-spaces",
      textAlign: "left",
    },
    age: {
      fontSize: 32,
      fontWeight: "600",
      lineHeight: "36px",
      letterSpacing: "0.4px",
      color: "#000",
      marginBottom: 32,
      whiteSpace: "break-spaces",
    },
    subHead: {
      fontSize: 20,
      fontWeight: "600",
      lineHeight: "28px",
      letterSpacing: "0.4px",
      color: "#757575",
      marginBottom: 160,
      textAlign: "left",
    },
    ctaText: {
      fontSize: 24,
      fontWeight: "700",
      lineHeight: "12px",
      letterSpacing: "0.4px",
      color: "#fff",
      backgroundColor: "#ff4e00",
      borderRadius: 32,
      padding: 14,
    },
    ctaIcon: {
      width: 20,
      height: 12,
      marginLeft: 8,
      objectFit: "contain",
    },
    heroImage: {
      height: 428,
      objectFit: "contain",
      marginBottom: "32px",
    },
    caraouselArea: {
      "& .control-dots": {
        textAlign: "right",
        bottom: "8px",
        right: "22%",
        margin: 0,
        "& li": {
          background: "#212121 !important",
          opacity: "1 !important",
          outline: "none",
          boxShadow: "none !important",
          height: "6px !important",
          width: "6px !important",
          margin: "0 3px !important",
        },
        "& li.selected": {
          background: "#ff4e00 !important",
          height: "6px !important",
          width: "17px !important",
          borderRadius: "8px",
          opacity: "1 !important",
        },
      },
    },
  },

  /* Medium desktop */
  "@media (min-width: 1240px) and (max-width: 1439px)": {
    headText: {
      fontSize: 54,
      fontWeight: "600",
      lineHeight: "68px",
      letterSpacing: "0.4px",
      color: "#000",
      marginBottom: 42,
      whiteSpace: "break-spaces",
      textAlign: "left",
    },
    age: {
      fontSize: 36,
      fontWeight: "600",
      lineHeight: "52px",
      letterSpacing: "0.4px",
      color: "#000",
      marginBottom: 42,
      whiteSpace: "break-spaces",
    },
    subHead: {
      fontSize: 20,
      fontWeight: "600",
      lineHeight: "26px",
      letterSpacing: "0.4px",
      color: "#757575",
      marginBottom: 140,
      textAlign: "left",
    },
    ctaText: {
      fontSize: 26,
      fontWeight: "700",
      lineHeight: "12px",
      letterSpacing: "0.4px",
      color: "#fff",
      backgroundColor: "#ff4e00",
      borderRadius: 32,
      padding: 18,
    },
    ctaIcon: {
      width: 20,
      height: 12,
      marginLeft: 8,
      objectFit: "contain",
    },
    heroImage: {
      height: 428,
      objectFit: "contain",
      marginBottom: "32px",
    },
    caraouselArea: {
      "& .control-dots": {
        textAlign: "right",
        bottom: "8px",
        right: "18%",
        margin: 0,
        "& li": {
          background: "#212121 !important",
          opacity: "1 !important",
          outline: "none",
          boxShadow: "none !important",
          height: "6px !important",
          width: "6px !important",
          margin: "0 3px !important",
        },
        "& li.selected": {
          background: "#ff4e00 !important",
          height: "6px !important",
          width: "17px !important",
          borderRadius: "8px",
          opacity: "1 !important",
        },
      },
    },
  },

  /* Large desktop */
  "@media (min-width: 1440px)": {
    headText: {
      fontSize: 64,
      fontWeight: "600",
      lineHeight: "78px",
      letterSpacing: "0.4px",
      color: "#000",
      marginBottom: 32,
      whiteSpace: "break-spaces",
      textAlign: "left",
    },
    age: {
      fontSize: 56,
      fontWeight: "600",
      lineHeight: "67px",
      letterSpacing: "0.4px",
      color: "#000",
      marginBottom: 32,
      whiteSpace: "break-spaces",
    },
    subHead: {
      fontSize: 24,
      fontWeight: "600",
      lineHeight: "32px",
      letterSpacing: "0.4px",
      color: "#757575",
      marginBottom: 100,
      textAlign: "left",
    },
    ctaText: {
      fontSize: 32,
      fontWeight: "700",
      lineHeight: "12px",
      letterSpacing: "0.4px",
      color: "#fff",
      backgroundColor: "#ff4e00",
      borderRadius: 32,
      padding: 24,
    },
    ctaIcon: {
      width: 20,
      height: 12,
      marginLeft: 8,
      objectFit: "contain",
    },
    heroImage: {
      height: 428,
      objectFit: "contain",
      marginBottom: "32px",
    },
    caraouselArea: {
      "& .control-dots": {
        textAlign: "right",
        bottom: "8px",
        right: "14%",
        margin: 0,
        "& li": {
          background: "#212121 !important",
          opacity: "1 !important",
          outline: "none",
          boxShadow: "none !important",
          height: "6px !important",
          width: "6px !important",
          margin: "0 3px !important",
        },
        "& li.selected": {
          background: "#ff4e00 !important",
          height: "6px !important",
          width: "17px !important",
          borderRadius: "8px",
          opacity: "1 !important",
        },
      },
    },
  },
  "@media (max-width: 550px)": {
    caraouselArea: {
      "& .control-dots": {
      right: "13%",
        "& li": {
          height: "6px !important",
          width: "6px !important",
          margin: "0 3px !important",
        },
        "& li.selected": {
          height: "6px !important",
          width: "17px !important",
        },
      },
    },
    scrollable: {
      width: "100%",
      height: "52px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #D8DEE8",
      borderRadius: "8px",
      margin: "20px 0px 16px -10px",
      color: "#424242",
      ...commonStyles.subtitle3,
      paddingLeft: "40px",
      appearance: "none",
      backgroundPosition: "calc(100% - 25px) center !important",
      background: `url(${bgIcon}) no-repeat`,
      "&::-webkit-appearance": {
        display: "none",
      },
      "&::-moz-appearance": {
        display: "none",
      },
      "&::-ms-appearance": {
        display: "none",
      },
    },

    locationIcon: {
      position: "relative",
      left: "18px",
    },
    input: {
      width: "100%",
      height: "42px",
      background: "#FFFFFF",
      border: "1px solid #eee",
      borderRadius: "11.5px",
      marginLeft: "-18px",
      ...commonStyles.headline4,
      lineHeight: "14px",
      // paddingLeft: "40px",
      marginTop: "15px",
      color: "#000",
      "&::placeholder": {
        color: "#A9A7A7",
      },
    },
    searchIcon: {
      position: "relative",
      left: "15px",
      color: "lightgray",
    },
    homeTraining: {
      height: "52px",
      background: "#FFFFFF",
      border: "1px solid #D8DEE8",
      borderRadius: "8px",
      margin: "20px 0px 16px 0px",
      color: "#424242",
      ...commonStyles.subtitle3,
      paddingLeft: "30px",
    },
    inputTwo: {
      width: "328px",
      height: "52px",
      background: "#FFFFFF",
      border: "1px solid #D8DEE8",
      borderRadius: "8px",
      marginLeft: "-12px",
      ...commonStyles.subtitle3,
      lineHeight: "14px",
      "&::placeholder": {
        paddingLeft: "40px",
      },
    },
    searchBtn: {
      padding: "14px 50px",
      background: "#ff4e00",
      borderRadius: "11px",
      width: "100%",
      marginTop: "24px",
      marginBottom: "0px",
      height: "42px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "16px",
      marginLeft: "0px",
    },
    activeSearchBtn: {
      background: "#ff4e00",
    },
    searchBtnText: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
    },
    searchIcon: {
      position: "relative",
      top: "28px",
      left: "18px",
      color: "lightgray",
    },
    carouselCard: {
      maxWidth: "550px",
      height: "auto",
      width: "100%",
      backgroundColor: "#fff",
      padding: "24px 12px 0px 12px",
      background: "none",
      position: "unset",
    },
    homeTrainingBtn: {
      height: "40px",
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #eee",
      borderRadius: "11px",
      lineHeight: "12px",
      fontSize: "11px",
      fontWeight: "700",
      color: "#424242",
      mixBlendMode: "normal",
      marginTop: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mobBtn: {
      margin: "16px auto 0px auto",
    },
    homeTrainingMobBtn: {
      width: "280px !important",
      whiteSpace: "nowrap",
      marginTop: "16px",
    },
    homeTrainingActiveBtn: {
      background: "#FF4E00",
      lineHeight: "12px",
      color: "#FFFFFF",
      mixBlendMode: "normal",
      height: "40px",
      width: "100%",
      borderRadius: "11px",
      fontSize: "11px",
      fontWeight: "700",
      mixBlendMode: "normal",
      marginTop: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "16px",
    },
    carouselTopText: {
      whiteSpace: "nowrap",
      ...commonStyles.subtitle1,
      display: "block",
      lineHeight: "100%",
      textTransform: "capitalize",
      color: "#212121",
      padding: "0px 100px 8px 0px",
      margin: "0",
    },
    carouselBottomText: {
      ...commonStyles.title1,
      display: "block",
      lineHeight: "100%",
      color: "#212121",
      padding: "0px 50px 20px 0px",
    },
    whiteBg: {
      background: "#edf3fd",
      padding: "24px",
      borderTopLeftRadius: "14px",
      borderTopRightRadius: "14px",
      marginBottom: "12px",
    },
    carouselActiveBtn: {
      ...commonStyles.title5_new,
      textAlign: "center",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      color: "#FF4E00",
      padding: "16px 0px",
      borderRadius: "28px",
      height: 44,
      display: "inline-flex",
      alignItems: "center",
    },
    carouselBtn: {
      ...commonStyles.title5_new,
      textAlign: "center",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      color: "#424242",
      padding: "16px 0px",
      borderRadius: "28px",
      height: 44,
      display: "inline-flex",
      alignItems: "center",
    },
    bottomLine: {
      width: "70%",
      display: "inline-block",
      borderBottom: "2px solid #FF4E00",
      paddingBottom: "10px",
    },
    mLeft: {
      marginLeft: "40px",
    },
    homeTrainingPrimaryBtn: {
      background: "#FF4E00",
      color: "#FFFFFF",
    },
    carouselBannerContainer: {
      padding: "32px 16px",
    },
    carouselBannerDiv: {
      display: "block",
      "& img": {
        display: "block",
        width: "100%",
        height: "100%",
      },
    },
    headText: {
      fontSize: 17,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      marginBottom: "8px",
    },
    age: {
      fontSize: 14,
      fontWeight: "600",
      lineHeight: "18px",
      letterSpacing: "0.4px",
      color: "#000",
      whiteSpace: "break-spaces",
    },
    subHead: {
      fontSize: 10,
      lineHeight: "12px",
      letterSpacing: "0.1x",
      marginBottom: 12,
    },
    ctaText: {
      fontSize: 10,
      lineHeight: "10px",
      letterSpacing: "0.1px",
      padding: "6px 12px",
    },
    ctaIcon: {
      height: 8,
      marginLeft: 4,
    },
    heroImage: {
      height: 132,
      objectFit: "contain",
    },
    flexTwo: {
      display: "flex",
    },
    flexColTwo: {
      flexDirection: "column",
    },
  },

  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignStart: {
    alignItems: "flex-start",
  },
  flexCol: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  alignEnd: {
    alignItems: "end",
  },
}));

export default style;
