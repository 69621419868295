import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import commonStyles from "../commonStyles";

export default function DeskRedirection() {
  const classes = useStyles();
  const commClasses = commonStyles();

  return (
    <>
      <div className={commClasses.mtb48lr120}>
        <div
          className={clsx(
            commClasses.flex,
            commClasses.b1EEE,
            commClasses.br40,
            classes.scrollContainer,
            commClasses.w80,
            commClasses.spaceBw,
            commClasses.pT24,
            commClasses.pB24,
            commClasses.pL32,
            commClasses.pR32,
            commClasses.marginauto,
            classes.w100,
            classes.mobCard
          )}
        >
          <div
            className={clsx(
              classes.catCard,
            )}
          >
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeHRjh5iIz-zS-iCeB1S8kPuosccr6MWV0ByZx4IAJ3nCfOVg/viewform" target="_blank" className={clsx(commClasses.flex, commClasses.centerAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/list-veue-icon.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20,commClasses.noWrap)}>
                List Your Venue
                </h4>
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.mT4,
                    commClasses.noWrap
                  )}
                >
                  and earn now!
                </span>
              </div>
            </a>
          </div>
          <div
            className={clsx(
              classes.catCard,
            )}
          >
            <a href="https://www.khelomore.com/events/explore-events/list-events-tournaments" target="_blank" className={clsx(commClasses.flex, commClasses.centerAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/event-icon.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20,commClasses.noWrap)}>
                Host Your Event
                </h4>
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.mT4,
                    commClasses.noWrap
                  )}
                >
                  Start hosting now
                </span>
              </div>
            </a>
          </div>
          <div
            className={clsx(
              classes.catCard,
            )}
          >
            <a href="https://linktr.ee/khelo_more" target="_blank" className={clsx(commClasses.flex, commClasses.centerAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/dwnld-icon.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20,commClasses.noWrap)}>
                Download the App
                </h4>
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.mT4,
                    commClasses.noWrap
                  )}
                >
                 Android and iOS
                </span>
              </div>
            </a>
          </div>
          <div
            className={clsx(
              classes.catCard,
            )}
          >
            <a href="https://www.khelomore.com/sports-venues/my-bookings" target="_blank" className={clsx(commClasses.flex, commClasses.centerAlign)}>
              <div>
                <img
                  className={clsx(classes.venueImg)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/calendar-icon.png"
                  alt="image"
                />
              </div>
              <div
                className={clsx(
                  commClasses.flex,
                  commClasses.flexCol,
                  commClasses.mL16,
                )}
              >
                <h4 className={clsx(commClasses.fs16fw6, commClasses.lineHeight20,commClasses.noWrap)}>
                Your Bookings
                </h4>
                <span
                  className={clsx(
                    commClasses.fs12fw6,
                    commClasses.gray,
                    commClasses.displayInlineBlock,
                    commClasses.mT4,
                    commClasses.noWrap
                  )}
                >
                 Upcoming & past
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
